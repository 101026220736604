import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import fetchValidationLogs from '../../actions/fetchValidationLogs';
import GridContainer from '../../common/components/Grid/GridContainer.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import Card from '../../common/components/Card/Card.jsx';
import CardHeader from '../../common/components/Card/CardHeader.jsx';
import CardBody from '../../common/components/Card/CardBody.jsx';

const ValidationLogs = ({
  classes,
  validationLogs,
  appName,
  fetchValidationLogs
}) => {
  useEffect(() => {
    fetchValidationLogs(appName);
  });

  validationLogs = validationLogs || { __HTML: '1' };
  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader className={classes.cardHeader} color="info">
            <h4 className={classes.cardTitleWhite}>
              {appName} Validation Logs
            </h4>
          </CardHeader>
          <CardBody>
            <div
              className={classes.cardCategory}
              dangerouslySetInnerHTML={validationLogs}
            />
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
};

export { ValidationLogs };

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => {
    const appName = ownProps.match.params.appName;
    return {
      appName,
      validationLogs: state.validationLogs
    };
  };
  return mapStateToProps;
};

const connected = connect(
  makeMapStateToProps,
  { fetchValidationLogs }
)(ValidationLogs);

export default withStyles(dashboardStyle)(connected);

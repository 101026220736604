const issueStatusesReducer = (startType, finishedType, errorType) => (
  state = { results: {}, meta: { fetching: false, errorMessages: [] } },
  action
) => {
  switch (action.type) {
    case startType:
      return { ...state, meta: { fetching: true, errorMessages: [] } };
    case finishedType:
      return {
        ...action.payload,
        meta: { fetching: false, errorMessages: [], lastUpdated: new Date() }
      };
    case errorType:
      return {
        ...state,
        meta: { fetching: false, errorMessages: action.payload }
      };
    default:
      return state;
  }
};

export default issueStatusesReducer;

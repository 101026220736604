import React, { useState, useCallback } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Line } from 'react-chartjs-2';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '../../common/components/Card/Card.jsx';
import CardHeader from '../../common/components/Card/CardHeader.jsx';
import CardBody from '../../common/components/Card/CardBody.jsx';
import GridItem from '../../common/components/Grid/GridItem.jsx';
import createChartData from '../functions/createLoadBalanceRequestChartData';

const AppHealthChart = ({
  classes,
  appHealthMonitorDetails,
  property,
  title,
  yAxisLabel,
  headerColor
}) => {
  const [duration, setValue] = useState('hour');
  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);
  const { dataLine, options } = createChartData(
    duration,
    appHealthMonitorDetails,
    property,
    yAxisLabel
  );
  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card>
        <CardHeader className={classes.cardHeader} color={headerColor}>
          <h4 className={classes.cardTitleWhite}>{title}</h4>
        </CardHeader>
        <CardBody>
          <Line data={dataLine} options={options} />
          <RadioGroup
            aria-label="position"
            name="position"
            value={duration}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="hour"
              control={<Radio color="primary" />}
              label="One Hour"
              labelPlacement="end"
            />
            <FormControlLabel
              value="day"
              control={<Radio color="primary" />}
              label="One Day"
              labelPlacement="end"
            />
            <FormControlLabel
              value="week"
              control={<Radio color="primary" />}
              label="One Week"
              labelPlacement="end"
            />
            <FormControlLabel
              value="month"
              control={<Radio color="primary" />}
              label="One Month"
              labelPlacement="end"
            />
          </RadioGroup>
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default AppHealthChart;

import React, { useMemo } from 'react';
import _ from 'lodash';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist';
import Card from '../../common/components/Card/Card.jsx';
import CardHeader from '../../common/components/Card/CardHeader.jsx';
import CardBody from '../../common/components/Card/CardBody.jsx';
import legend from 'chartist-plugin-legend';
import ctAxisTitle from 'chartist-plugin-axistitle';
import Spinner from './Spinner';
import RefreshCardFooter from '../../common/components/Card/RefreshCardFooter.jsx';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { TextField, MenuItem } from '@material-ui/core';

const createData = counts => {
  const groupedData = _.groupBy(counts.results, 'projectName');
  const labels = Object.keys(groupedData);
  const URLgroup = _.groupBy(counts.results, 'projectKey');
  const groupedCategoryNames = _.groupBy(counts.results, 'categoryName');
  const series = Object.keys(groupedCategoryNames).reduce(
    (acc, groupedCategoryName) => {
      acc[groupedCategoryName] = { name: groupedCategoryName, data: [] };
      return acc;
    },
    {}
  );

  const totals = _(counts.results)
    .groupBy('projectName')
    .mapValues(groupedProject => {
      return { total: _.sumBy(groupedProject, 'count') };
    })
    .value();

  counts.results.forEach(({ projectName, categoryName, count }) => {
    const index = labels.indexOf(projectName);
    series[categoryName].data[index] =
      _.divide(count / totals[projectName].total) * 100;
  });
  const URLKeys = [];
  Object.keys(URLgroup).forEach((item, i) => {
    URLKeys.push([
      '<a href= "https://saicagile.atlassian.net/browse/' +
        item +
        '" target="_blank" rel="noopener noreferrer" style="color: black;font-size: 13px;display: inline-block;line-height: normal;">' +
        labels[i] +
        '</a>'
    ]);
  });

  const data = {
    labels: URLKeys,
    series: Object.values(series)
  };

  return data;
};

Chartist.plugins = Chartist.plugins || {};
Chartist.plugins.ctHtmlLabels = function(options) {
  return function(chart) {
    chart.on('draw', function(context) {
      if (context.type === 'label') {
        // Best to combine with something like sanitize-html
        context.element.empty()._node.innerHTML = context.text;
      }
    });
  };
};

const getChartMeta = ({ axisTitle }) => ({
  options: {
    seriesBarDistance: 10,
    stackBars: true,
    plugins: [
      Chartist.plugins.ctHtmlLabels(),
      legend(),
      ctAxisTitle({
        axisX: {
          axisTitle: '% of Tasks',
          axisClass: `${axisTitle}`,
          offset: {
            x: 0,
            y: 50
          }
        }
      })
    ],
    horizontalBars: true,
    axisX: {
      onlyInteger: true,
      showGrid: false,
      offset: 50
    },
    axisY: {
      offset: 170,
      onlyInteger: true
    },
    low: 0,
    high: 100,
    chartPadding: {
      top: 0,
      right: 25,
      bottom: 0,
      left: 0
    }
  },
  responsiveOptions: [
    [
      'screen and (max-width: 640px)',
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function(value) {
            return value[0];
          }
        }
      }
    ]
  ],
  animation: {
    draw: function(data) {
      if (data.type === 'bar') {
        var label = new Chartist.Svg('text');
        label.text(`${_.round(data.value.x)}%`);
        label.attr({
          x: _.mean([data.x1, data.x2]),
          y: data.y1 + 3,
          'text-anchor': 'middle',
          style: 'font-size:9px; color: black; font-weight: bold;'
        });
        data.group.append(label);
        if (label.width() + 2 > data.element.width()) {
          label.remove();
        } else {
          label.animate({
            opacity: {
              begin: (data.index + 1) * 80,
              dur: 500,
              from: 0,
              to: 1,
              easing: 'ease'
            }
          });
        }
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * 80,
            dur: 500,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    }
  }
});

const styles = theme => ({
  cardHeader: {
    padding: '15px',
    marginTop: '-20px',
    borderRadius: '3px',
    background: '#465337',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4)',
    color: '#FFF',
    margin: '0 15px',
    position: 'relative',
    zIndex: '3 !important',
    borderBottom: 'none',
    display: 'flex'
  },
  cardCategory: {
    color: 'white',
    backgroundColor: '#6f8753',
    fontWeight: '500',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    borderStyle: 'none',
    fontSize: 'unset',
    height: '35px',
    position: 'relative'
  },
  titleSection: {
    float: 'left',
    flex: 8
  },
  cardTitleWhite: {
    color: 'white',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: 'gray',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  button: {
    backgroundColor: '#6F8753',
    color: 'white',
    margin: '2%',
    marginTop: '0px',
    marginBottom: '0px',
    flex: 1
  }
});

const AllAppsMigrationStatusChart = ({
  counts,
  classes,
  footerClass,
  fetchProjectStatusCategoryCounts
}) => {
  const showError = counts.meta.errorMessages.length > 0;
  const showSpinner = !showError && counts.meta.fetching;
  const showChart = !showError && !showSpinner && !counts.meta.fetching;
  const chartMeta = useMemo(() => getChartMeta(classes), [classes]);
  return (
    <Card chart>
      <CardHeader className={classes.cardHeader}>
        <div className={classes.titleSection}>
          <h4 className={classes.cardTitleWhite}>Sprint Status</h4>
        </div>
        <TextField
          select
          variant="outlined"
          value={1}
          InputProps={{
            style: {
              color: 'white',
              backgroundColor: '#6f8753',
              borderRadius: '4px',
              borderStyle: 'none',
              height: '36px',
              marginTop: '6px',
              boxShadow:
                '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
            }
          }}
        >
          <MenuItem value="0">
            <em>All</em>
          </MenuItem>
          <MenuItem value="1">BEPB Portfolio</MenuItem>
        </TextField>
        <a
          className="tooltip"
          style={{
            color: '#809c5f',
            marginTop: '30px',
            position: 'absolute'
          }}
          href="https://saicagile.atlassian.net/secure/PortfolioPlanView.jspa?id=18&sid=18#backlog"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Portfolio
        </a>
      </CardHeader>
      {useMemo(
        () => (
          <CardBody>
            {showError && <h4>Error retrieving data from JIRA</h4>}
            {showSpinner && <Spinner />}
            {showChart && (
              <ChartistGraph
                className="ct-chart"
                data={createData(counts)}
                type="Bar"
                options={chartMeta.options}
                responsiveOptions={chartMeta.responsiveOptions}
                listener={chartMeta.animation}
              />
            )}
          </CardBody>
        ),
        [
          chartMeta.animation,
          chartMeta.options,
          chartMeta.responsiveOptions,
          counts,
          showChart,
          showError,
          showSpinner
        ]
      )}

      <RefreshCardFooter
        footerClass={footerClass}
        refreshAction={fetchProjectStatusCategoryCounts}
        lastUpdated={counts.meta.lastUpdated}
      />
    </Card>
  );
};

export default connect()(withStyles(styles)(AllAppsMigrationStatusChart));

import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import iconsStyle from 'assets/jss/material-dashboard-react/views/iconsStyle.jsx';

function Icons(props) {
  return (
    <h5>
      <i>Content Coming Soon...</i>
    </h5>
  );
}

Icons.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(iconsStyle)(Icons);

const applicationsQueue = [
  { applicationName: 'AF App 17', priority: '12' },
  { applicationName: 'AF App 18', priority: '15' },
  { applicationName: 'AF App 19', priority: '36' }
];

const fetchAppsInPriorityQueue = () => dispatch => {
  const dataRows = [];
  applicationsQueue.forEach(item => {
    dataRows.push([item.applicationName, item.priority]);
  });
  dispatch({
    type: 'APPS_IN_PRIORITY_QUEUE',
    payload: dataRows
  });
};

export default fetchAppsInPriorityQueue;

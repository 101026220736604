const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';

const addItemToCartSuccess = item => {
  return { type: ADD_ITEM_TO_CART, payload: item };
};

const addItemToCart = item => dispatch => {
  dispatch(addItemToCartSuccess(item));
};

export { ADD_ITEM_TO_CART };
export default addItemToCart;

import React from 'react';
import Card from '../../common/components/Card/Card.jsx';
import CardHeader from '../../common/components/Card/CardHeader.jsx';
import CardBody from '../../common/components/Card/CardBody.jsx';
import Table from '../../Panels/components/AppReleaseTable.jsx';
import { withStyles } from '@material-ui/core/styles';
import fetchAllAppsReleaseChart from '../../Panels/functions/fetchAllAppsReleaseChart';
import { connect } from 'react-redux';
import RefreshCardFooter from '../../common/components/Card/RefreshCardFooter.jsx';
import { TextField, MenuItem } from '@material-ui/core';

const styles = theme => ({
  cardHeader: {
    padding: '15px',
    marginTop: '-20px',
    borderRadius: '3px',
    background: '#465337',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4)',
    color: '#FFF',
    margin: '0 15px',
    position: 'relative',
    zIndex: '3 !important',
    borderBottom: 'none',
    display: 'flex'
  },
  titleSection: {
    float: 'left',
    flex: 1
  },
  buttonContainer: {
    display: 'flex',
    flex: 1
  },
  cardTitle: {
    marginTop: '0px',
    minHeight: 'auto',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardCategory: {
    color: 'white',
    backgroundColor: '#6f8753',
    fontWeight: '500',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    borderStyle: 'none',
    fontSize: 'unset',
    height: '35px',
    position: 'relative'
  },
  card: {
    maxWidth: 400
  },
  button: {
    backgroundColor: '#a54040',
    color: 'white',
    margin: '2%',
    marginTop: '6px',
    flex: 1
  }
});

const AllAppsReleaseChart = ({
  classes,
  fetchAllAppsReleaseChart,
  appReleaseChart,
  footerClass
}) => {
  var timestamp = new Date();
  if (appReleaseChart != null) timestamp = appReleaseChart[0].timestamp;

  return (
    <Card>
      <CardHeader className={classes.cardHeader}>
        <div className={classes.titleSection}>
          <h4 className={classes.cardTitle}>Release Calendar</h4>
        </div>
        <TextField
          select
          variant="outlined"
          value={1}
          InputProps={{
            style: {
              color: 'white',
              backgroundColor: '#6f8753',
              borderRadius: '4px',
              height: '36px',
              marginTop: '6px',
              boxShadow:
                '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
            }
          }}
        >
          <MenuItem value="0">
            <em>All</em>
          </MenuItem>
          <MenuItem value="1">BEPB Portfolio</MenuItem>
        </TextField>
        <a
          className="tooltip"
          style={{
            color: '#809c5f',
            marginTop: '30px',
            position: 'absolute'
          }}
          href="https://saicagile.atlassian.net/secure/PortfolioPlanView.jspa?id=18&sid=18#backlog"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Portfolio
        </a>
      </CardHeader>
      <CardBody>
        <Table
          tableHead={['Project Name', 'Upcoming Release', 'Following Release']}
          tableData={{
            appReleaseChart: appReleaseChart || []
          }}
        />
      </CardBody>
      <RefreshCardFooter footerClass={footerClass} lastUpdated={timestamp} />
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    appReleaseChart: state.appReleaseChart.data
  };
};

export default connect(
  mapStateToProps,
  { fetchAllAppsReleaseChart }
)(withStyles(styles)(AllAppsReleaseChart));

// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
// core components/views for Admin layout
import DashboardPage from 'features/Dashboard/components/Dashboard.jsx';
import AppHealthMonitorDetails from 'features/Dashboard/components/AppHealthMonitorDetails';
import SystemAvailibilityDetails from 'features/Panels/SystemAvailibilityDetails.js';
import SystemValidationDetails from 'features/Panels/SystemValidationDetails.js';
import KnowledgeBase from 'features/knowledgeBase/components/KnowledgeBase';
import Typography from 'features/Typography/components/Typography.jsx';
import Icons from 'features/Icons/components/Icons.jsx';
import FinancialDetails from 'features/Dashboard/components/FinancialDetails';
import CyberHealthDetails from 'features/Dashboard/components/CyberReadinessDetails';
import WorkflowDetails from 'features/Dashboard/components/WorkflowDetails';
import ValidationLogs from 'features/Dashboard/components/ValidationLogs';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    showInSideBar: true,
    component: DashboardPage,
    layout: '/admin'
  },
  {
    path: '/knowledge-base',
    name: 'Knowledge Base',
    rtlName: 'قائمة الجدول',
    icon: 'lightbulb',
    showInSideBar: true,
    component: KnowledgeBase,
    layout: '/admin'
  },
  {
    path: '/app-status/:appName',
    name: 'App Health Monitor',
    rtlName: 'Ù„ÙˆØ­Ø© Ø§Ù„Ù‚ÙŠØ§Ø¯Ø©',
    icon: Dashboard,
    showInSideBar: false,
    component: AppHealthMonitorDetails,
    layout: '/admin'
  },
  {
    path: '/system-details/:healthType',
    name: 'System Availability Details',
    rtlName: 'Ù„ÙˆØ­Ø© Ø§Ù„Ù‚ÙŠØ§Ø¯Ø©',
    icon: Dashboard,
    showInSideBar: false,
    component: SystemAvailibilityDetails,
    layout: '/admin'
  },
  {
    path: '/community',
    name: 'App Dev Community',
    rtlName: 'الرموز',
    icon: 'comments',
    showInSideBar: true,
    component: Icons,
    layout: '/admin'
  },
  {
    path: '/get-help',
    name: 'Get Help',
    rtlName: 'طباعة',
    icon: 'life-ring',
    showInSideBar: true,
    component: Typography,
    layout: '/admin'
  },
  {
    path: '/FinancialDetails',
    name: 'Financial',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    showInSideBar: false,
    component: FinancialDetails,
    layout: '/admin'
  },
  {
    path: '/CyberReadinessDetails',
    name: 'CyberReadiness',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    showInSideBar: false,
    component: CyberHealthDetails,
    layout: '/admin'
  },
  {
    path: '/validation-details/:passType',
    name: 'System Validation Details',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    showInSideBar: false,
    component: SystemValidationDetails,
    layout: '/admin'
  },
  {
    path: '/Validation/Logs/:appName',
    name: 'Validation Logs',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    showInSideBar: false,
    component: ValidationLogs,
    layout: '/admin'
  },
  {
    path: '/WorkflowDetails',
    name: 'WorkflowDetails',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    showInSideBar: false,
    component: WorkflowDetails,
    layout: '/admin'
  }
];

export default dashboardRoutes;

import React from 'react';
import Card from '../common/components/Card/Card.jsx';
import CardHeader from '../common/components/Card/CardHeader.jsx';
import CardBody from '../common/components/Card/CardBody.jsx';
import GridItem from '../common/components/Grid/GridItem.jsx';
import Table from './components/SystemDetailsTable.jsx';
import { withStyles } from '@material-ui/core/styles';
import fetchSystemDetails from './functions/fetchSystemDetails';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  cardHeader: {
    padding: '15px',
    marginTop: '-20px',
    borderRadius: '3px',
    background: '#28485F',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4)',
    color: '#FFF',
    margin: '0 15px',
    position: 'relative',
    zIndex: '3 !important',
    borderBottom: 'none',
    display: 'flex'
  },
  titleSection: {
    float: 'left',
    flex: 18
  },
  buttonContainer: {
    display: 'flex',
    flex: 1
  },
  cardTitle: {
    marginTop: '0px',
    minHeight: 'auto',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTitleWhite: {
    color: 'white',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: 'gray',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  cardCategory: {
    color: '#edaaa5',
    margin: 0,
    marginTop: 0,
    marginBottom: 0
  },
  card: {
    maxWidth: 400
  },
  cardBody: {
    height: 800
  },
  button: {
    backgroundColor: '#457CA3',
    color: 'white',
    margin: '2%',
    flex: 1
  },
  successText: {
    color: 'green'
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px'
  },
  stats: {
    color: 'gray',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    }
  },
  cardCategoryWhite: {
    color: 'white',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  axisTitle: {
    fill: 'white',
    fillOpacity: 0.8
  },
  paperWrapper: {
    backgroundColor: '#fafafa',
    [theme.breakpoints.up('md')]: {
      padding: '10px'
    },
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none'
    },
    padding: '10px 0px'
  }
});

const SystemAvailibilityDetails = ({
  classes,
  match: { params },
  fetchSystemDetails,
  systemDetails,
  showAppDetails
}) => {
  return (
    <GridItem xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardHeader className={classes.cardHeader}>
          <div className={classes.titleSection}>
            <h4 className={classes.cardTitle}>
              App Health Monitor - {params.healthType}
            </h4>
          </div>
          <div className={classes.buttonContainer}>
            <Link
              component={RouterLink}
              to="/admin/dashboard"
              style={{ textDecoration: 'none' }}
            >
              <Button className={classes.button}>Return</Button>
            </Link>
          </div>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="warning"
            tableHead={[
              'Application Name',
              'System Up',
              'Health Status',
              'Apdex Score',
              'Error Rate',
              'Instance Count',
              'Response Time',
              'Throughput'
            ]}
            tableData={systemDetails || []}
            tableFilter={params.healthType}
            showAppDetails={showAppDetails}
          />
        </CardBody>
      </Card>
    </GridItem>
  );
};

const mapStateToProps = state => {
  return {
    systemDetails: state.systemdetail.data
  };
};

export default connect(
  mapStateToProps,
  { fetchSystemDetails }
)(withStyles(styles)(SystemAvailibilityDetails));

const appReleaseChart = {
  data: [
    {
      applicationName: 'New Development - ASBS20',
      description: 'Release 1.0: 2019-09-16',
      severity: 'Release 1.1: 2019-09-30'
    },
    {
      applicationName: 'New Development - AER',
      description: 'Release 1.0: 2019-09-23',
      severity: 'Release 1.1: 2019-10-07'
    },
    {
      applicationName: 'OM- Single Evaluation Processing System',
      description: 'Release 2.6: 2019-09-26',
      severity: 'Release 2.7: 2019-10-10'
    }
  ]
};

const replaceWithRealRequest = Promise.resolve(appReleaseChart);

const fetchAllAppsReleaseChart = () => dispatch =>
  replaceWithRealRequest.then(searchResults => {
    dispatch({ type: 'APPRELEASE', payload: searchResults });
  });

export default fetchAllAppsReleaseChart;

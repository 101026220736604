const appStatus = {
  data: [
    {
      applicationName: 'AF App 3',
      healthStatus: 'ok',
      healthInfo: 'Status check OK',
      cloudServiceProvider: 'Amazon Web Services'
    },
    {
      applicationName: 'AF App 4',
      healthStatus: 'warning',
      healthInfo: 'Network monitoring requires attention',
      cloudServiceProvider: 'Microsoft Azure'
    },
    {
      applicationName: 'AF App 5',
      healthStatus: 'error',
      healthInfo: 'CPU utilization > 95% for more than 3 minutes',
      cloudServiceProvider: 'Amazon Web Services'
    }
  ]
};

const replaceWithRealRequest = Promise.resolve(appStatus);

const fetchAppStatus = () => dispatch =>
  replaceWithRealRequest.then(searchResults => {
    dispatch({ type: 'APPSTATUS', payload: searchResults });
  });

export default fetchAppStatus;

import moment from 'moment';
import _ from 'lodash';

const LAST_HOUR_MOCK_DATA_INDEX = 7;
const LAST_DAY_MOCK_DATA_INDEX = 19;
const LAST_WEEK_MOCK_DATA_INDEX = 25;
const LAST_MONTH_MOCK_DATA_INDEX = 49;

const calculateDataSize = duration => {
  if (duration === 'hour') {
    return LAST_HOUR_MOCK_DATA_INDEX;
  } else if (duration === 'day') {
    return LAST_DAY_MOCK_DATA_INDEX;
  } else if (duration === 'week') {
    return LAST_WEEK_MOCK_DATA_INDEX;
  } else if (duration === 'month') {
    return LAST_MONTH_MOCK_DATA_INDEX;
  }
};

const chartFormatter = [
  {
    duration: 'hour',
    format: 'mm',
    dataSize: calculateDataSize('hour'),
    unit: 'minute',
    unitStepSize: 10
  },
  {
    duration: 'day',
    format: 'HH:mm',
    dataSize: calculateDataSize('day'),
    unit: 'hour',
    unitStepSize: 3
  },
  {
    duration: 'week',
    format: 'MMM D',
    dataSize: calculateDataSize('week'),
    unit: 'day',
    unitStepSize: 1
  },
  {
    duration: 'month',
    format: 'MMM D',
    dataSize: calculateDataSize('month'),
    unit: 'day',
    unitStepSize: 5
  }
];

const createChartData = (duration, appStatusDetails) => {
  const yValue = [],
    xValue = [];
  const { format, unit, unitStepSize, dataSize } = _.find(chartFormatter, {
    duration
  });
  const colorPicker = {
    '200': 'blue',
    '401': '#77ABFF',
    '402': 'green',
    '404': 'lightGreen',
    '500': 'orange'
  };
  appStatusDetails.forEach((appStatusDetail, index) => {
    if (index < dataSize) {
      yValue.push(appStatusDetail.values['num_errors']);
      xValue.push(moment.utc(appStatusDetail.time).format(format));
    }
  });
  const dataLine = {
    labels: ['200', '401', '402', '404', '500'],
    datasets: [
      {
        fill: false,
        backgroundColor: [],
        pointRadius: 0,
        data: yValue
      }
    ]
  };
  let dataset = dataLine.datasets[0];
  dataLine.labels.forEach(dataLineLabel => {
    dataset.backgroundColor.push(colorPicker[dataLineLabel]);
  });

  const options = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            unit,
            unitStepSize,
            parser: format
          }
        }
      ]
    }
  };
  return { dataLine, options };
};

export default createChartData;

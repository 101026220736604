import React from 'react';
import Card from '../common/components/Card/Card.jsx';
import CardHeader from '../common/components/Card/CardHeader.jsx';
import CardBody from '../common/components/Card/CardBody.jsx';
import GridItem from '../common/components/Grid/GridItem.jsx';
import { CAMUNDA_URL } from '../constants/camundaConstants';
import { withStyles } from '@material-ui/core/styles';
import fetchValidationBreakdown from '../actions/fetchValidationBreakdown.js';
import { connect } from 'react-redux';
import RefreshCardFooter from '../common/components/Card/RefreshCardFooter.jsx';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  cardHeader: {
    padding: '15px',
    marginTop: '-20px',
    borderRadius: '3px',
    background: '#28485F',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4)',
    color: '#FFF',
    margin: '0 15px',
    position: 'relative',
    zIndex: '3 !important',
    borderBottom: 'none',
    display: 'flex'
  },
  titleSection: {
    height: '20px',
    float: 'left',
    flex: 8
  },
  buttonContainer: {
    display: 'flex',
    flex: 1
  },
  cardTitle: {
    marginTop: '0px',
    minHeight: 'auto',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTitleWhite: {
    color: 'white',
    margin: '0',
    minHeight: 'auto',
    lineHeight: '1',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: 'gray',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  cardCategory: {
    color: '#edaaa5',
    margin: 0,
    marginTop: 0,
    marginBottom: 0
  },
  card: {
    maxWidth: 400
  },
  cardBody: {
    height: 800
  },
  button: {
    backgroundColor: '#457CA3',
    color: 'white',
    margin: '2%',
    flex: 1
  },
  successText: {
    color: 'green'
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px'
  },
  stats: {
    color: 'gray',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    }
  },
  cardCategoryWhite: {
    color: 'white',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  axisTitle: {
    fill: 'white',
    fillOpacity: 0.8
  },
  paperWrapper: {
    backgroundColor: '#fafafa',
    [theme.breakpoints.up('md')]: {
      padding: '10px'
    },
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none'
    },
    padding: '10px 0px'
  }
});

const WorkflowSummary = ({
  classes,
  misfire,
  defam,
  ipermsError,
  completedEvaluations,
  timestamp,
  footerClass
}) => {
  const totalOpen =
    misfire && defam && ipermsError ? misfire + defam + ipermsError : 0;
  return (
    <GridItem>
      <Card>
        <CardHeader className={classes.cardHeader}>
          <div className={classes.titleSection}>
            <h4 className={classes.cardTitleWhite}>Evaluation Count</h4>
            <div
              className="tooltip"
              style={{ color: '#457CA3', cursor: 'auto' }}
            >
              Notional Business Metric
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Link
              href={`${CAMUNDA_URL}/app/cockpit/default/#/process-definition`}
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className={classes.button}>Details</Button>
            </Link>
          </div>
        </CardHeader>
        <CardBody>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div
                style={{
                  margin: 'auto',
                  flexGrow: '1'
                }}
              >
                <span>Open Evaluations</span>
              </div>
              <div
                style={{
                  margin: 'auto'
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: '2em'
                  }}
                >
                  {totalOpen}
                </span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div
                style={{
                  margin: 'auto',
                  flexGrow: '1'
                }}
              >
                <span>iPerms Processing Errors</span>
              </div>
              <div
                style={{
                  margin: 'auto'
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: '2em'
                  }}
                >
                  {ipermsError}
                </span>
              </div>
            </div>
            <div
              style={{
                height: '48px',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
              }}
            >
              <div
                style={{
                  margin: 'auto',
                  flexGrow: '1'
                }}
              >
                <span>Completed Evaluations</span>
              </div>
              <div
                style={{
                  margin: 'auto'
                }}
              >
                <span>{completedEvaluations}</span>
              </div>
            </div>
          </div>
        </CardBody>
        <RefreshCardFooter footerClass={footerClass} lastUpdated={timestamp} />
      </Card>
    </GridItem>
  );
};

const mapStateToProps = state => {
  return {
    misfire: state.workflowSummary.misfire,
    defam: state.workflowSummary.defam,
    ipermsError: state.workflowSummary.ipermsError,
    completedEvaluations: state.workflowSummary.completedEvaluations,
    timestamp: new Date()
  };
};

export default connect(
  mapStateToProps,
  { fetchValidationBreakdown }
)(withStyles(styles)(WorkflowSummary));

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

class ArticleSearchBar extends React.Component {
  state = {
    term: ''
  };

  onFormSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.state.term);
  };

  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.onFormSubmit} autoComplete="off">
        <TextField
          id="outlined-full-width"
          type="search"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          fullWidth
          onChange={e => this.setState({ term: e.target.value })}
        />
      </form>
    );
  }
}

export default ArticleSearchBar;

import axios from 'axios';

const proxy =
  'https://zcibromvld.execute-api.us-east-1.amazonaws.com/dev/?url=';

export default axios.create({
  baseURL: `${proxy}https://saicagile.atlassian.net/rest/api/2`,
  headers: {
    'Content-Type': 'application/json'
  }
});

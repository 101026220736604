import {
  PROFILE_SELECTED,
  DEFAULT_PROFILE_ID
} from '../features/constants/userProfileConstants';

export default (state = DEFAULT_PROFILE_ID, action) => {
  switch (action.type) {
    case PROFILE_SELECTED:
      return action.payload;
    default:
      return state;
  }
};

import React from 'react';
import Refresh from '@material-ui/icons/Refresh';
import CardFooter from './CardFooter.jsx';
import LastUpdated from '../LastUpdated/LastUpdated.jsx';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const refreshFooterStyles = () => ({
  footerContent: {
    flexGrow: 1
  },
  refresh: {
    float: 'right'
  }
});

function RefreshCardFooter({
  classes,
  refreshAction,
  lastUpdated,
  footerClass
}) {
  return (
    <CardFooter chart>
      <div className={classNames(footerClass, classes.footerContent)}>
        <div className={classes.footerContent}>
          <LastUpdated lastUpdated={lastUpdated} />
          {refreshAction && (
            <a
              href="#refresh"
              onClick={refreshAction}
              className={classes.refresh}
            >
              <Refresh />
            </a>
          )}
        </div>
      </div>
    </CardFooter>
  );
}

RefreshCardFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  footerClass: PropTypes.string.isRequired,
  refreshAction: PropTypes.func,
  lastUpdated: PropTypes.object
};

export default withStyles(refreshFooterStyles)(RefreshCardFooter);

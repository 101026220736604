import React, { useMemo } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.08)'
    },
    '& span': {
      color: 'rgba(0,0,255,0.87)'
    }
  }
};

const ConceptsList = ({ classes }) => {
  return useMemo(
    () => (
      <List>
        <ListItem
          component="a"
          href="/HRCAgileDevelopmentProposal.pdf"
          target="_blank"
          className={classes.listItem}
        >
          Agile Development Proposal at HRC
        </ListItem>
        <ListItem
          component="a"
          href="/ApplicationModernization-At-a-Glance.pdf"
          target="_blank"
          className={classes.listItem}
        >
          Application Modernization | At a Glance
        </ListItem>
        <ListItem
          component="a"
          href="/ApplicationModernization-CloudMigration.pdf"
          target="_blank"
          className={classes.listItem}
        >
          Application Modernization | Cloud Migration
        </ListItem>
        <ListItem
          component="a"
          href="/ApplicationModernization-Legacy-Apps.pdf"
          target="_blank"
          className={classes.listItem}
        >
          Application Modernization | Legacy Applications
        </ListItem>
        <ListItem
          component="a"
          href="/SAIC-DTPaaS.pdf"
          target="_blank"
          className={classes.listItem}
        >
          SAIC Compliant Dev Test Platform Service
        </ListItem>
        <ListItem
          component="a"
          href="/SAFeIntroduction.pdf"
          target="_blank"
          className={classes.listItem}
        >
          SAFe 4.6 Introduction | SAIC White Paper
        </ListItem>
        <ListItem
          component="a"
          href="/SAFeScaledAgile.pdf"
          target="_blank"
          className={classes.listItem}
        >
          SAFe by Scaled Agile
        </ListItem>
        <ListItem
          component="a"
          href="/IntelligentSoftware.pptx"
          target="_blank"
          className={classes.listItem}
        >
          Intelligent Software
        </ListItem>
      </List>
    ),
    [classes]
  );
};

export default withStyles(styles)(ConceptsList);

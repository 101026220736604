const mockData = [
  {
    applicationName: 'App 1',
    validationPass: 'Warning',
    time: '8/13/2019 2000'
  },
  {
    applicationName: 'App 2',
    validationPass: 'Failed',
    time: '8/13/2019 2000'
  },
  {
    applicationName: 'App 3',
    validationPass: 'Success',
    time: '8/13/2019 2000'
  },
  {
    applicationName: 'App 4',
    validationPass: 'Success',
    time: '8/13/2019 2000'
  },
  {
    applicationName: 'App 5',
    validationPass: 'Success',
    time: '8/13/2019 2000'
  },
  {
    applicationName: 'App 6',
    validationPass: 'Success',
    time: '8/13/2019 2000'
  },
  {
    applicationName: 'App 7',
    validationPass: 'Success',
    time: '8/13/2019 1897'
  },
  {
    applicationName: 'App 8',
    validationPass: 'Success',
    time: '8/13/2019 2141'
  },
  {
    applicationName: 'App 9',
    validationPass: 'Success',
    time: '8/13/2019 2168'
  },
  {
    applicationName: 'App 10',
    validationPass: 'Success',
    time: '8/13/2019 1849'
  },
  {
    applicationName: 'App 11',
    validationPass: 'Success',
    time: '8/13/2019 1816'
  },
  {
    applicationName: 'App 12',
    validationPass: 'Success',
    time: '8/13/2019 2140'
  },
  {
    applicationName: 'App 13',
    validationPass: 'Success',
    time: '8/13/2019 2092'
  },
  {
    applicationName: 'App 14',
    validationPass: 'Success',
    time: '8/13/2019 1812'
  },
  {
    applicationName: 'App 15',
    validationPass: 'Success',
    time: '8/13/2019 2115'
  },
  {
    applicationName: 'App 16',
    validationPass: 'Success',
    time: '8/13/2019 1810'
  },
  {
    applicationName: 'App 17',
    validationPass: 'Success',
    time: '8/13/2019 1959'
  },
  {
    applicationName: 'App 18',
    validationPass: 'Success',
    time: '8/13/2019 2015'
  },
  {
    applicationName: 'App 19',
    validationPass: 'Success',
    time: '8/13/2019 1960'
  },
  {
    applicationName: 'App 20',
    validationPass: 'Success',
    time: '8/13/2019 2120'
  },
  {
    applicationName: 'App 21',
    validationPass: 'Success',
    time: '8/13/2019 2090'
  },
  {
    applicationName: 'App 22',
    validationPass: 'Success',
    time: '8/13/2019 2128'
  },
  {
    applicationName: 'App 23',
    validationPass: 'Success',
    time: '8/13/2019 2012'
  },
  {
    applicationName: 'App 24',
    validationPass: 'Success',
    time: '8/13/2019 1879'
  },
  {
    applicationName: 'App 25',
    validationPass: 'Success',
    time: '8/13/2019 2115'
  },
  {
    applicationName: 'App 26',
    validationPass: 'Success',
    time: '8/13/2019 2116'
  },
  {
    applicationName: 'App 27',
    validationPass: 'Success',
    time: '8/13/2019 2196'
  },
  {
    applicationName: 'App 28',
    validationPass: 'Success',
    time: '8/13/2019 2001'
  },
  {
    applicationName: 'App 29',
    validationPass: 'Success',
    time: '8/13/2019 1964'
  },
  {
    applicationName: 'App 30',
    validationPass: 'Success',
    time: '8/13/2019 2102'
  },
  {
    applicationName: 'App 31',
    validationPass: 'Success',
    time: '8/13/2019 2023'
  },
  {
    applicationName: 'App 32',
    validationPass: 'Success',
    time: '8/13/2019 2065'
  },
  {
    applicationName: 'App 33',
    validationPass: 'Success',
    time: '8/13/2019 1965'
  },
  {
    applicationName: 'App 34',
    validationPass: 'Success',
    time: '8/13/2019 1984'
  },
  {
    applicationName: 'App 35',
    validationPass: 'Success',
    time: '8/13/2019 1857'
  },
  {
    applicationName: 'App 36',
    validationPass: 'Success',
    time: '8/13/2019 1840'
  },
  {
    applicationName: 'App 37',
    validationPass: 'Success',
    time: '8/13/2019 1988'
  },
  {
    applicationName: 'App 38',
    validationPass: 'Success',
    time: '8/13/2019 1901'
  },
  {
    applicationName: 'App 39',
    validationPass: 'Success',
    time: '8/13/2019 1830'
  },
  {
    applicationName: 'App 40',
    validationPass: 'Success',
    time: '8/13/2019 2164'
  },
  {
    applicationName: 'App 41',
    validationPass: 'Success',
    time: '8/13/2019 1956'
  },
  {
    applicationName: 'App 42',
    validationPass: 'Success',
    time: '8/13/2019 1949'
  },
  {
    applicationName: 'App 43',
    validationPass: 'Success',
    time: '8/13/2019 2114'
  },
  {
    applicationName: 'App 44',
    validationPass: 'Success',
    time: '8/13/2019 1984'
  },
  {
    applicationName: 'App 45',
    validationPass: 'Success',
    time: '8/13/2019 1980'
  },
  {
    applicationName: 'App 46',
    validationPass: 'Success',
    time: '8/13/2019 2181'
  },
  {
    applicationName: 'App 47',
    validationPass: 'Success',
    time: '8/13/2019 2117'
  },
  {
    applicationName: 'App 48',
    validationPass: 'Success',
    time: '8/13/2019 2039'
  },
  {
    applicationName: 'App 49',
    validationPass: 'Success',
    time: '8/13/2019 1896'
  },
  {
    applicationName: 'App 50',
    validationPass: 'Success',
    time: '8/13/2019 2172'
  },
  {
    applicationName: 'App 51',
    validationPass: 'Success',
    time: '8/13/2019 1993'
  },
  {
    applicationName: 'App 52',
    validationPass: 'Success',
    time: '8/13/2019 2076'
  },
  {
    applicationName: 'App 53',
    validationPass: 'Success',
    time: '8/13/2019 2192'
  },
  {
    applicationName: 'App 54',
    validationPass: 'Success',
    time: '8/13/2019 1928'
  },
  {
    applicationName: 'App 55',
    validationPass: 'Success',
    time: '8/13/2019 2194'
  },
  {
    applicationName: 'App 56',
    validationPass: 'Success',
    time: '8/13/2019 1913'
  },
  {
    applicationName: 'App 57',
    validationPass: 'Success',
    time: '8/13/2019 2161'
  },
  {
    applicationName: 'App 58',
    validationPass: 'Success',
    time: '8/13/2019 2132'
  },
  {
    applicationName: 'App 59',
    validationPass: 'Success',
    time: '8/13/2019 1976'
  },
  {
    applicationName: 'App 60',
    validationPass: 'Success',
    time: '8/13/2019 1933'
  },
  {
    applicationName: 'App 61',
    validationPass: 'Success',
    time: '8/13/2019 2050'
  },
  {
    applicationName: 'App 62',
    validationPass: 'Success',
    time: '8/13/2019 2069'
  },
  {
    applicationName: 'App 63',
    validationPass: 'Success',
    time: '8/13/2019 2167'
  },
  {
    applicationName: 'App 64',
    validationPass: 'Success',
    time: '8/13/2019 2017'
  },
  {
    applicationName: 'App 65',
    validationPass: 'Success',
    time: '8/13/2019 1950'
  },
  {
    applicationName: 'App 66',
    validationPass: 'Success',
    time: '8/13/2019 1936'
  },
  {
    applicationName: 'App 67',
    validationPass: 'Success',
    time: '8/13/2019 2152'
  },
  {
    applicationName: 'App 68',
    validationPass: 'Success',
    time: '8/13/2019 2081'
  },
  {
    applicationName: 'App 69',
    validationPass: 'Success',
    time: '8/13/2019 2186'
  },
  {
    applicationName: 'App 70',
    validationPass: 'Warning',
    time: '8/13/2019 2159'
  },
  {
    applicationName: 'App 71',
    validationPass: 'Success',
    time: '8/13/2019 2115'
  },
  {
    applicationName: 'App 72',
    validationPass: 'Success',
    time: '8/13/2019 1988'
  },
  {
    applicationName: 'App 73',
    validationPass: 'Success',
    time: '8/13/2019 1800'
  },
  {
    applicationName: 'App 74',
    validationPass: 'Success',
    time: '8/13/2019 2061'
  },
  {
    applicationName: 'App 75',
    validationPass: 'Success',
    time: '8/13/2019 2001'
  },
  {
    applicationName: 'App 76',
    validationPass: 'Success',
    time: '8/13/2019 2011'
  },
  {
    applicationName: 'App 77',
    validationPass: 'Success',
    time: '8/13/2019 2031'
  },
  {
    applicationName: 'App 78',
    validationPass: 'Success',
    time: '8/13/2019 1959'
  },
  {
    applicationName: 'App 79',
    validationPass: 'Success',
    time: '8/13/2019 1988'
  },
  {
    applicationName: 'App 80',
    validationPass: 'Success',
    time: '8/13/2019 1857'
  },
  {
    applicationName: 'App 81',
    validationPass: 'Success',
    time: '8/13/2019 2093'
  },
  {
    applicationName: 'App 82',
    validationPass: 'Success',
    time: '8/13/2019 2032'
  },
  {
    applicationName: 'App 83',
    validationPass: 'Success',
    time: '8/13/2019 1846'
  },
  {
    applicationName: 'App 84',
    validationPass: 'Success',
    time: '8/13/2019 1824'
  },
  {
    applicationName: 'App 85',
    validationPass: 'Success',
    time: '8/13/2019 1881'
  },
  {
    applicationName: 'App 86',
    validationPass: 'Success',
    time: '8/13/2019 1879'
  },
  {
    applicationName: 'App 87',
    validationPass: 'Success',
    time: '8/13/2019 1919'
  },
  {
    applicationName: 'App 88',
    validationPass: 'Success',
    time: '8/13/2019 1882'
  },
  {
    applicationName: 'App 89',
    validationPass: 'Success',
    time: '8/13/2019 1807'
  },
  {
    applicationName: 'App 90',
    validationPass: 'Success',
    time: '8/13/2019 2164'
  },
  {
    applicationName: 'App 91',
    validationPass: 'Success',
    time: '8/13/2019 2147'
  },
  {
    applicationName: 'App 92',
    validationPass: 'Success',
    time: '8/13/2019 2126'
  },
  {
    applicationName: 'App 93',
    validationPass: 'Success',
    time: '8/13/2019 2107'
  },
  {
    applicationName: 'App 94',
    validationPass: 'Success',
    time: '8/13/2019 1932'
  },
  {
    applicationName: 'App 95',
    validationPass: 'Success',
    time: '8/13/2019 2018'
  },
  {
    applicationName: 'App 96',
    validationPass: 'Success',
    time: '8/13/2019 1870'
  },
  {
    applicationName: 'App 97',
    validationPass: 'Success',
    time: '8/13/2019 2122'
  },
  {
    applicationName: 'App 98',
    validationPass: 'Success',
    time: '8/13/2019 2006'
  },
  {
    applicationName: 'App 99',
    validationPass: 'Success',
    time: '8/13/2019 2174'
  },
  {
    applicationName: 'App 100',
    validationPass: 'Success',
    time: '8/13/2019 1907'
  },
  {
    applicationName: 'App 101',
    validationPass: 'Success',
    time: '8/13/2019 2087'
  },
  {
    applicationName: 'App 102',
    validationPass: 'Success',
    time: '8/13/2019 2153'
  },
  {
    applicationName: 'App 103',
    validationPass: 'Success',
    time: '8/13/2019 1871'
  },
  {
    applicationName: 'App 104',
    validationPass: 'Success',
    time: '8/13/2019 2181'
  },
  {
    applicationName: 'App 105',
    validationPass: 'Success',
    time: '8/13/2019 2011'
  },
  {
    applicationName: 'App 106',
    validationPass: 'Success',
    time: '8/13/2019 1952'
  },
  {
    applicationName: 'App 107',
    validationPass: 'Success',
    time: '8/13/2019 1949'
  },
  {
    applicationName: 'App 108',
    validationPass: 'Success',
    time: '8/13/2019 2068'
  },
  {
    applicationName: 'App 109',
    validationPass: 'Success',
    time: '8/13/2019 2161'
  },
  {
    applicationName: 'App 110',
    validationPass: 'Success',
    time: '8/13/2019 2096'
  },
  {
    applicationName: 'App 111',
    validationPass: 'Success',
    time: '8/13/2019 1818'
  },
  {
    applicationName: 'App 112',
    validationPass: 'Success',
    time: '8/13/2019 2135'
  },
  {
    applicationName: 'App 113',
    validationPass: 'Success',
    time: '8/13/2019 2096'
  },
  {
    applicationName: 'App 114',
    validationPass: 'Success',
    time: '8/13/2019 2152'
  },
  {
    applicationName: 'App 115',
    validationPass: 'Success',
    time: '8/13/2019 2044'
  },
  {
    applicationName: 'App 116',
    validationPass: 'Success',
    time: '8/13/2019 2040'
  },
  {
    applicationName: 'App 117',
    validationPass: 'Success',
    time: '8/13/2019 2199'
  },
  {
    applicationName: 'App 118',
    validationPass: 'Success',
    time: '8/13/2019 1977'
  },
  {
    applicationName: 'App 119',
    validationPass: 'Success',
    time: '8/13/2019 1952'
  },
  {
    applicationName: 'App 120',
    validationPass: 'Success',
    time: '8/13/2019 1810'
  },
  {
    applicationName: 'App 121',
    validationPass: 'Success',
    time: '8/13/2019 1971'
  },
  {
    applicationName: 'App 122',
    validationPass: 'Success',
    time: '8/13/2019 1855'
  },
  {
    applicationName: 'App 123',
    validationPass: 'Success',
    time: '8/13/2019 2118'
  },
  {
    applicationName: 'App 124',
    validationPass: 'Success',
    time: '8/13/2019 2086'
  },
  {
    applicationName: 'App 125',
    validationPass: 'Success',
    time: '8/13/2019 1835'
  },
  {
    applicationName: 'App 126',
    validationPass: 'Success',
    time: '8/13/2019 2014'
  },
  {
    applicationName: 'App 127',
    validationPass: 'Success',
    time: '8/13/2019 2156'
  },
  {
    applicationName: 'App 128',
    validationPass: 'Success',
    time: '8/13/2019 2115'
  },
  {
    applicationName: 'App 129',
    validationPass: 'Success',
    time: '8/13/2019 1862'
  },
  {
    applicationName: 'App 130',
    validationPass: 'Success',
    time: '8/13/2019 1934'
  },
  {
    applicationName: 'App 131',
    validationPass: 'Success',
    time: '8/13/2019 2153'
  },
  {
    applicationName: 'App 132',
    validationPass: 'Success',
    time: '8/13/2019 1920'
  },
  {
    applicationName: 'App 133',
    validationPass: 'Success',
    time: '8/13/2019 2044'
  },
  {
    applicationName: 'App 134',
    validationPass: 'Success',
    time: '8/13/2019 2027'
  },
  {
    applicationName: 'App 135',
    validationPass: 'Success',
    time: '8/13/2019 1933'
  },
  {
    applicationName: 'App 136',
    validationPass: 'Success',
    time: '8/13/2019 1976'
  },
  {
    applicationName: 'App 137',
    validationPass: 'Success',
    time: '8/13/2019 1978'
  },
  {
    applicationName: 'App 138',
    validationPass: 'Failed',
    time: '8/13/2019 1903'
  },
  {
    applicationName: 'App 139',
    validationPass: 'Success',
    time: '8/13/2019 1831'
  },
  {
    applicationName: 'App 140',
    validationPass: 'Success',
    time: '8/13/2019 2064'
  },
  {
    applicationName: 'App 141',
    validationPass: 'Success',
    time: '8/13/2019 2067'
  },
  {
    applicationName: 'App 142',
    validationPass: 'Success',
    time: '8/13/2019 1997'
  },
  {
    applicationName: 'App 143',
    validationPass: 'Success',
    time: '8/13/2019 2112'
  },
  {
    applicationName: 'App 144',
    validationPass: 'Success',
    time: '8/13/2019 2029'
  },
  {
    applicationName: 'App 145',
    validationPass: 'Success',
    time: '8/13/2019 2178'
  },
  {
    applicationName: 'App 146',
    validationPass: 'Success',
    time: '8/13/2019 1886'
  },
  {
    applicationName: 'App 147',
    validationPass: 'Success',
    time: '8/13/2019 2065'
  },
  {
    applicationName: 'App 148',
    validationPass: 'Success',
    time: '8/13/2019 1948'
  },
  {
    applicationName: 'App 149',
    validationPass: 'Success',
    time: '8/13/2019 2090'
  },
  {
    applicationName: 'App 150',
    validationPass: 'Success',
    time: '8/13/2019 2122'
  },
  {
    applicationName: 'App 151',
    validationPass: 'Success',
    time: '8/13/2019 2047'
  },
  {
    applicationName: 'App 152',
    validationPass: 'Success',
    time: '8/13/2019 1842'
  },
  {
    applicationName: 'App 153',
    validationPass: 'Success',
    time: '8/13/2019 2049'
  },
  {
    applicationName: 'App 154',
    validationPass: 'Success',
    time: '8/13/2019 1942'
  },
  {
    applicationName: 'App 155',
    validationPass: 'Success',
    time: '8/13/2019 1899'
  },
  {
    applicationName: 'App 156',
    validationPass: 'Success',
    time: '8/13/2019 2183'
  },
  {
    applicationName: 'App 157',
    validationPass: 'Success',
    time: '8/13/2019 2185'
  },
  {
    applicationName: 'App 158',
    validationPass: 'Success',
    time: '8/13/2019 1955'
  },
  {
    applicationName: 'App 159',
    validationPass: 'Success',
    time: '8/13/2019 2191'
  },
  {
    applicationName: 'App 160',
    validationPass: 'Success',
    time: '8/13/2019 1925'
  },
  {
    applicationName: 'App 161',
    validationPass: 'Success',
    time: '8/13/2019 2163'
  },
  {
    applicationName: 'App 162',
    validationPass: 'Success',
    time: '8/13/2019 1870'
  },
  {
    applicationName: 'App 163',
    validationPass: 'Success',
    time: '8/13/2019 1864'
  },
  {
    applicationName: 'App 164',
    validationPass: 'Success',
    time: '8/13/2019 2187'
  },
  {
    applicationName: 'App 165',
    validationPass: 'Success',
    time: '8/13/2019 2195'
  },
  {
    applicationName: 'App 166',
    validationPass: 'Success',
    time: '8/13/2019 2006'
  },
  {
    applicationName: 'App 167',
    validationPass: 'Success',
    time: '8/13/2019 2040'
  },
  {
    applicationName: 'App 168',
    validationPass: 'Success',
    time: '8/13/2019 1889'
  },
  {
    applicationName: 'App 169',
    validationPass: 'Success',
    time: '8/13/2019 2058'
  },
  {
    applicationName: 'App 170',
    validationPass: 'Success',
    time: '8/13/2019 1925'
  },
  {
    applicationName: 'App 171',
    validationPass: 'Success',
    time: '8/13/2019 2036'
  },
  {
    applicationName: 'App 172',
    validationPass: 'Success',
    time: '8/13/2019 1802'
  },
  {
    applicationName: 'App 173',
    validationPass: 'Success',
    time: '8/13/2019 2161'
  },
  {
    applicationName: 'App 174',
    validationPass: 'Success',
    time: '8/13/2019 2113'
  },
  {
    applicationName: 'App 175',
    validationPass: 'Success',
    time: '8/13/2019 1935'
  },
  {
    applicationName: 'App 176',
    validationPass: 'Success',
    time: '8/13/2019 2110'
  },
  {
    applicationName: 'App 177',
    validationPass: 'Success',
    time: '8/13/2019 1947'
  },
  {
    applicationName: 'App 178',
    validationPass: 'Success',
    time: '8/13/2019 1822'
  },
  {
    applicationName: 'App 179',
    validationPass: 'Success',
    time: '8/13/2019 1903'
  },
  {
    applicationName: 'App 180',
    validationPass: 'Success',
    time: '8/13/2019 2139'
  },
  {
    applicationName: 'App 181',
    validationPass: 'Success',
    time: '8/13/2019 2094'
  },
  {
    applicationName: 'App 182',
    validationPass: 'Success',
    time: '8/13/2019 2174'
  }
];

const fetchSystemDetails = () => dispatch => {
  dispatch({ type: 'VALIDATIONDETAIL', payload: mockData });
};

export default fetchSystemDetails;

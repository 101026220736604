import axios from 'axios';

const proxy =
  'https://zcibromvld.execute-api.us-east-1.amazonaws.com/dev/?url=';

export const BASE_URL = 'https://saicagile.atlassian.net/wiki';

export default axios.create({
  baseURL: `${proxy}${BASE_URL}/rest/api/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

import React from 'react';
import Card from '../../common/components/Card/Card.jsx';
import CardHeader from '../../common/components/Card/CardHeader.jsx';
import CardBody from '../../common/components/Card/CardBody.jsx';
import { CAMUNDA_URL } from '../../constants/camundaConstants';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  cardHeader: {
    padding: '15px',
    marginTop: '-20px',
    borderRadius: '3px',
    background: '#28485F',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4)',
    color: '#FFF',
    margin: '0 15px',
    position: 'relative',
    zIndex: '3 !important',
    borderBottom: 'none',
    display: 'flex'
  },
  titleSection: {
    float: 'left',
    flex: 18
  },
  buttonContainer: {
    display: 'flex',
    flex: 1
  },
  cardTitle: {
    marginTop: '0px',
    minHeight: 'auto',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTitleWhite: {
    color: 'white',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: 'gray',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  cardCategory: {
    color: '#edaaa5',
    margin: 0,
    marginTop: 0,
    marginBottom: 0
  },
  card: {
    maxWidth: 400
  },
  cardBody: {
    height: 800
  },
  button: {
    backgroundColor: '#457CA3',
    color: 'white',
    margin: '2%',
    flex: 1
  },
  successText: {
    color: 'green'
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px'
  },
  stats: {
    color: 'gray',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    }
  },
  cardCategoryWhite: {
    color: 'white',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  axisTitle: {
    fill: 'white',
    fillOpacity: 0.8
  },
  paperWrapper: {
    backgroundColor: '#fafafa',
    [theme.breakpoints.up('md')]: {
      padding: '10px'
    },
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none'
    },
    padding: '10px 0px'
  }
});

//Seems to work better with laptop sizes
const iframeDivStyle = { height: '70vh' };

const WorkflowSummaryDetails = props => {
  return (
    <Card>
      <CardHeader className={props.classes.cardHeader}>
        <div className={props.classes.titleSection}>
          <h4 className={props.classes.cardTitleWhite}>Workflow Details</h4>
        </div>
        <div className={props.classes.buttonContainer}>
          <Link
            component={RouterLink}
            to="/admin/dashboard"
            style={{ textDecoration: 'none' }}
          >
            <Button className={props.classes.button}>Return</Button>
          </Link>
        </div>
      </CardHeader>
      <CardBody>
        <div className="WorkflowSummaryDetails" style={iframeDivStyle}>
          <iframe
            height="100%"
            width="100%"
            src={`${CAMUNDA_URL}/app/cockpit/default/#/process-definition`}
            frameBorder="0"
            allowFullScreen={true}
            title="WorkflowSummaryDetails"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default withStyles(styles)(WorkflowSummaryDetails);

import moment from 'moment';
import _ from 'lodash';

const LAST_HOUR_MOCK_DATA_INDEX = 22;
const LAST_DAY_MOCK_DATA_INDEX = 38;
const LAST_WEEK_MOCK_DATA_INDEX = 44;
const LAST_MONTH_MOCK_DATA_INDEX = 67;

const calculateDataSize = duration => {
  if (duration === 'hour') {
    return LAST_HOUR_MOCK_DATA_INDEX;
  } else if (duration === 'day') {
    return LAST_DAY_MOCK_DATA_INDEX;
  } else if (duration === 'week') {
    return LAST_WEEK_MOCK_DATA_INDEX;
  } else if (duration === 'month') {
    return LAST_MONTH_MOCK_DATA_INDEX;
  }
};

const chartFormatter = [
  {
    duration: 'hour',
    format: 'mm',
    dataSize: calculateDataSize('hour'),
    unit: 'minute',
    unitStepSize: 10
  },
  {
    duration: 'day',
    format: 'HH:mm',
    dataSize: calculateDataSize('day'),
    unit: 'hour',
    unitStepSize: 3
  },
  {
    duration: 'week',
    format: 'MMM D',
    dataSize: calculateDataSize('week'),
    unit: 'day',
    unitStepSize: 1
  },
  {
    duration: 'month',
    format: 'MMM D',
    dataSize: calculateDataSize('month'),
    unit: 'day',
    unitStepSize: 5
  }
];

const createChartData = (
  duration,
  appStatusDetails,
  appStatusProperty,
  yAxisLabel
) => {
  const yValue = [],
    xValue = [];
  const { format, unit, unitStepSize, dataSize } = _.find(chartFormatter, {
    duration
  });
  appStatusDetails.forEach((appStatusDetail, index) => {
    if (index < dataSize) {
      yValue.push(appStatusDetail.values[appStatusProperty]);
      xValue.push(moment.utc(appStatusDetail.from).format(format));
    }
  });
  const dataLine = {
    labels: xValue,
    datasets: [
      {
        fill: false,
        backgroundColor: 'rgba(184, 185, 210, .3)',
        borderColor: 'rgb(35, 26, 136)',
        pointRadius: 0,
        data: yValue
      }
    ]
  };
  const options = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            unit,
            unitStepSize,
            parser: format
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: yAxisLabel ? true : false,
            labelString: yAxisLabel
          }
        }
      ]
    }
  };
  return { dataLine, options };
};

export default createChartData;

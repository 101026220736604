const featuredArticlesReducer = (startType, finishedType, errorType) => (
  state = {
    results: [],
    meta: { fetching: false, errorMessage: '' }
  },
  action
) => {
  switch (action.type) {
    case startType:
      return { ...state, meta: { fetching: true, errorMessage: '' } };
    case finishedType:
      return {
        results: [...action.payload],
        meta: { fetching: false, errorMessage: '', lastUpdated: new Date() }
      };
    case errorType:
      return {
        ...state,
        meta: { fetching: false, errorMessage: action.payload }
      };
    default:
      return state;
  }
};

export default featuredArticlesReducer;

import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import AccessTime from '@material-ui/icons/AccessTime';

const formatter = (value, unit, suffix, epochSeconds, nextFormatter) => {
  const timeString = unit === 'second' ? 'just now' : nextFormatter();
  return `last updated ${timeString}`;
};

function LastUpdated({ lastUpdated = new Date() }) {
  return (
    <>
      <AccessTime />{' '}
      <TimeAgo date={lastUpdated} minPeriod={60} formatter={formatter} />
    </>
  );
}

LastUpdated.propTypes = {
  lastUpdated: PropTypes.object
};

export default LastUpdated;

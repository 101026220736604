const services = `
[
	{
		"name": "Identity and Access Management (IdAM)",
		"slug": "idam",
		"icon": "id-card",
		"serviceChildren": [
		  {
				"name": "Identity and Access Management (IdAM) - Application Users",
				"slug": "idam-application-users",
				"description": "CCE SSO user identity, data and CAC authentication for end‐users accessing application websites.",
				"serviceChildren": []
				},
				{
				"name": "Identity and Access Management (IdAM) - Azure Portal",
				"slug": "idam-azure-portal",
				"description": "CCE SSO user identity and CAC authentication for cloud infrastructure administrators accessing the CSP management portal.",
				"serviceChildren": []
				},
				{
				"name": "Identity and Access Management (IdAM) - Server Administrators",
				"slug": "idam-server-administrators",
				"description": "Management of user identity, CAC authentication, and access control for server administrators.",
				"serviceChildren": []
				},
				{
				"name": "Directory Services",
				"slug": "directory-services",
				"description": "Servers and supporting cloud resources to provide AD Domain Controller services to Windows infrastructure servers and AD aware applications.",
				"serviceChildren": []
				},
				{
				"name": "Domain Name Service (DNS) - CSP Internal",
				"slug": "dns-csp-internal",
				"description": "DNS servers to forward/cache DNS requests from cloud applications/servers to government NIPRNET global DNS servers.",
				"serviceChildren": []
				},
				{
				"name": "Domain Name Service (DNS) - Public",
				"slug": "dns-public",
				"description": "DNS servers to forward/cache DNS requests from cloud apps/servers to government NIPRNET global DNS servers. MSP will submit public DNS requests to 24TH.",
				"serviceChildren": []
				},
				{
					"name": "DHCP",
					"slug": "dhcp",
					"description": "Server that provides dynamically assigned IP addresses to other servers on the same network.",
					"serviceChildren": []
				}
		]
	},
	{
		"name": "Mail & File Transfer",
		"slug": "mail-file-transfer",
		"icon": "envelope",
		"serviceChildren": [
			{
				"name": "File Transfer Service (SFTP, Enterprise Service Bus)",
				"slug": "fts-sftp-esb",
				"description": "SFTP Servers to securely transfers files to/from the commercial Internet into DoD cloud application enclaves. CSP will deliver this service through the MSP.",
				"serviceChildren": []
			},
			{
				"name": "Mail Gateway/SMTP Relay",
				"slug": "mail-gateway-smtp",
				"description": "DoD mail relay servers authorized by DISA to forward email from servers/applications in commercial cloud enclaves to DISA NIPRNet email gateways. CSP will deliver this service through the MSP.",
				"serviceChildren": []
			}
		]
	},
	{
		"name": "Monitoring",
		"slug":"monitoring",
		"icon": "eye",
		"serviceChildren": [
			{
				"name": "Network Infrastructure Monitoring",
				"slug": "network-monitoring",
				"description": "Software and servers to monitor and alert on network and operating system resource utilization and availability. CSP will deliver this service through the MSP.",
				"serviceChildren": []
			},
			{
				"name": "DoDIN Monitoring",
				"slug": "dodin-monitoring",
				"description": "There DoDIN monitoring handled by DISA.",
				"serviceChildren": []
				},
				{
				"name": "Operating System Monitoring",
				"slug": "operating-system-monitoring",
				"description": "Software and servers to monitor and alert on network and operating system resource utilization and availability. CSP will deliver this service through the MSP.",
				"serviceChildren": []
				}
		]
	},
	{
		"name": "Misc Application Services",
		"slug": "misc-application-services",
		"icon": "folder-open",
		"serviceChildren": [
			{
				"name": "Network Time Protocol (NTP)",
				"slug": "ntp",
				"description": "NTP servers to provide Cybersecurity mandated DoD time source for all DoD servers and applications.",
				"serviceChildren": []
			},
			{
				"name": "PKI/SSL Certificate Validation (OCSP)",
				"slug": "ocsp",
				"description": "OCSP Servers to validate DoD SSL certificates of servers/applications by checking them against DISA NIPRNet Certificate Revocation List servers.",
				"serviceChildren": []
			},
			{
				"name": "Remote Access Service",
				"slug": "remote-access-service",
				"description": "Servers, software, and labor to manage remote desktop/VPN solution to provide system administrators secure access to DoD servers in cloud enclaves from their commercial Internet workstations (app SIs).",
				"serviceChildren": []
			},
			{
				"name": "Message Queues",
				"slug": "message-queues",
				"description": "Server that centrally manages app-to-app data messaging. Only needed for software/applications developed to use a message queuing service.",
				"serviceChildren": []
			}
		]
	}
]
`;

const readAllServicesApi = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(JSON.parse(services));
    }, 1000);
  });
};

export default readAllServicesApi;

import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Button from '../CustomButtons/Button.jsx';
import MenuItem from '@material-ui/core/MenuItem';
import Person from '@material-ui/icons/Person';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Poppers from '@material-ui/core/Popper';
import selectProfile from '../../../actions/userProfileActions';
import { PROFILES } from '../../../constants/userProfileConstants';

class UserProfile extends React.Component {
  state = {
    open: false
  };
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.props.selectProfile(event.target.id);
    this.setState({ open: false });
  };

  getMenuItems = (selectedProfileId, dropdownItem) => {
    return _.map(Object.keys(PROFILES), profileKey => {
      const profileValue = PROFILES[profileKey];
      return (
        <MenuItem
          id={profileKey}
          key={profileKey + profileValue}
          onClick={this.handleClose}
          selected={selectedProfileId === profileKey}
          className={dropdownItem}
        >
          {profileValue}
        </MenuItem>
      );
    });
  };

  render() {
    const { classes, selectedProfileId } = this.props;
    const { open } = this.state;
    return (
      <>
        <Button
          buttonRef={node => {
            this.anchorEl = node;
          }}
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Person"
          aria-owns={open ? 'menu-list-grow' : null}
          aria-haspopup="true"
          onClick={this.handleToggle}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
        </Button>
        <Poppers
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !open }) +
            ' ' +
            classes.pooperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList role="menu">
                    {this.getMenuItems(selectedProfileId, classes.dropdownItem)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </>
    );
  }
}

export default connect(
  null,
  { selectProfile }
)(UserProfile);

const migrationCostScheduleTrackerData = [
  {
    applicationName: 'AF App 10',
    currentMigrationCost: '$223,342 +$3,231',
    sprintProgress: '6 of 11',
    estimatedCompletion: '19 Aug 2019 +12 days'
  },
  {
    applicationName: 'AF App 11',
    currentMigrationCost: '$154,532 -$2,012',
    sprintProgress: '3 of 10',
    estimatedCompletion: '16 Sep 2019 -3 days'
  },
  {
    applicationName: 'AF App 12',
    currentMigrationCost: '$3,310 -$344',
    sprintProgress: '-',
    estimatedCompletion: '30 Sep 2019'
  }
];

const MIGRATION_COST_SCHEDULE_TRACKER = 'MIGRATION_COST_SCHEDULE_TRACKER';

const fetchMigrationCostScheduleTrackerData = () => dispatch => {
  const dataRows = [];
  migrationCostScheduleTrackerData.forEach((item, i) => {
    dataRows.push([
      item.applicationName,
      item.currentMigrationCost,
      item.sprintProgress,
      item.estimatedCompletion
    ]);
  });
  dispatch({ type: MIGRATION_COST_SCHEDULE_TRACKER, payload: dataRows });
};

export { MIGRATION_COST_SCHEDULE_TRACKER };
export default fetchMigrationCostScheduleTrackerData;

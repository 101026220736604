/*eslint-disable*/
import React, { Component } from 'react';
import classnames from 'classnames';

import Button from '../CustomButtons/Button.jsx';

class FixedPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: 'dropdown show',
      bg_checked: true,
      bgImage: this.props.bgImage
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.props.handleFixedClick();
  }
  render() {
    return (
      <div
        className={classnames('fixed-plugin', {
          'rtl-fixed-plugin': this.props.rtlActive
        })}
      >
        <div id="fixedPluginClasses" className={this.props.fixedClasses}>
          <div className="menu-gear" onClick={this.handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu">
            <li className="header-title">SIDEBAR FILTERS</li>
            <li className="adjustments-line">
              <a className="switch-trigger">
                <div>
                  <span
                    className={
                      this.props.bgColor === 'purple'
                        ? 'badge filter badge-purple active'
                        : 'badge filter badge-purple'
                    }
                    data-color="purple"
                    onClick={() => {
                      this.props.handleColorClick('purple');
                    }}
                  />
                  <span
                    className={
                      this.props.bgColor === 'blue'
                        ? 'badge filter badge-blue active'
                        : 'badge filter badge-blue'
                    }
                    data-color="blue"
                    onClick={() => {
                      this.props.handleColorClick('blue');
                    }}
                  />
                  <span
                    className={
                      this.props.bgColor === 'green'
                        ? 'badge filter badge-green active'
                        : 'badge filter badge-green'
                    }
                    data-color="green"
                    onClick={() => {
                      this.props.handleColorClick('green');
                    }}
                  />
                  <span
                    className={
                      this.props.bgColor === 'red'
                        ? 'badge filter badge-red active'
                        : 'badge filter badge-red'
                    }
                    data-color="red"
                    onClick={() => {
                      this.props.handleColorClick('red');
                    }}
                  />
                  <span
                    className={
                      this.props.bgColor === 'orange'
                        ? 'badge filter badge-orange active'
                        : 'badge filter badge-orange'
                    }
                    data-color="orange"
                    onClick={() => {
                      this.props.handleColorClick('orange');
                    }}
                  />
                </div>
              </a>
            </li>

            <li className="button-container">
              <div className="button-container">
                <Button color="success" target="_blank" fullWidth>
                  Add Widget
                </Button>
              </div>
            </li>
            <li className="button-container">
              <Button color="info" fullWidth target="_blank">
                Edit Layout
              </Button>
            </li>
            <li className="adjustments-line" />
          </ul>
        </div>
      </div>
    );
  }
}

export default FixedPlugin;

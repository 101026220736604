//import newRelic from '../apis/newRelic';

const mockMetricData = {
  data: {
    metric_data: {
      metrics: [
        {
          timeslices: [
            {
              from: '2019-08-18T17:58:00+00:00',
              to: '2019-08-18T18:00:00+00:00',
              values: {
                cpu_utilization: 38,
                average_response_time: 77,
                average_value: 55,
                call_count: 3657,
                calls_per_minute: 299,
                max_response_time: 141,
                error_rate: 17,
                requests_per_minute: 257,
                memory_utilization: 65,
                total_call_time_per_minute: 252,
                error_type: 'Error 1',
                num_errors: 20
              }
            },
            {
              from: '2019-08-18T17:56:00+00:00',
              to: '2019-08-18T17:58:00+00:00',
              values: {
                cpu_utilization: 29,
                average_response_time: 51,
                average_value: 62,
                call_count: 2227,
                calls_per_minute: 266,
                max_response_time: 107,
                error_rate: 19,
                requests_per_minute: 228,
                memory_utilization: 63,
                total_call_time_per_minute: 231,
                error_type: 'Error 2',
                num_errors: 10
              }
            },
            {
              from: '2019-08-18T17:54:00+00:00',
              to: '2019-08-18T17:56:00+00:00',
              values: {
                cpu_utilization: 25,
                average_response_time: 67,
                average_value: 60,
                call_count: 2577,
                calls_per_minute: 362,
                max_response_time: 149,
                error_rate: 20,
                requests_per_minute: 379,
                memory_utilization: 57,
                total_call_time_per_minute: 323,
                error_type: 'Error 3',
                num_errors: 15
              }
            },
            {
              from: '2019-08-18T17:52:00+00:00',
              to: '2019-08-18T17:54:00+00:00',
              values: {
                cpu_utilization: 25,
                average_response_time: 65,
                average_value: 51,
                call_count: 2234,
                calls_per_minute: 278,
                max_response_time: 109,
                error_rate: 16,
                requests_per_minute: 312,
                memory_utilization: 52,
                total_call_time_per_minute: 391,
                error_type: 'Error 4',
                num_errors: 5
              }
            },
            {
              from: '2019-08-18T17:50:00+00:00',
              to: '2019-08-18T17:52:00+00:00',
              values: {
                cpu_utilization: 35,
                average_response_time: 71,
                average_value: 76,
                call_count: 3475,
                calls_per_minute: 337,
                max_response_time: 145,
                error_rate: 16,
                requests_per_minute: 225,
                memory_utilization: 67,
                total_call_time_per_minute: 322,
                error_type: 'Error 5',
                num_errors: 2
              }
            },
            {
              from: '2019-08-18T17:48:00+00:00',
              to: '2019-08-18T17:50:00+00:00',
              values: {
                cpu_utilization: 37,
                average_response_time: 59,
                average_value: 72,
                call_count: 2047,
                calls_per_minute: 278,
                max_response_time: 142,
                error_rate: 17,
                requests_per_minute: 375,
                memory_utilization: 50,
                total_call_time_per_minute: 203
              }
            },
            {
              from: '2019-08-18T17:46:00+00:00',
              to: '2019-08-18T17:48:00+00:00',
              values: {
                cpu_utilization: 29,
                average_response_time: 70,
                average_value: 57,
                call_count: 2842,
                calls_per_minute: 365,
                max_response_time: 145,
                error_rate: 18,
                requests_per_minute: 340,
                memory_utilization: 63,
                total_call_time_per_minute: 221
              }
            },
            {
              from: '2019-08-18T17:44:00+00:00',
              to: '2019-08-18T17:46:00+00:00',
              values: {
                cpu_utilization: 20,
                average_response_time: 67,
                average_value: 62,
                call_count: 3109,
                calls_per_minute: 220,
                max_response_time: 148,
                error_rate: 18,
                requests_per_minute: 275,
                memory_utilization: 51,
                total_call_time_per_minute: 366
              }
            },
            {
              from: '2019-08-18T17:42:00+00:00',
              to: '2019-08-18T17:44:00+00:00',
              values: {
                cpu_utilization: 23,
                average_response_time: 52,
                average_value: 71,
                call_count: 3829,
                calls_per_minute: 360,
                max_response_time: 143,
                error_rate: 16,
                requests_per_minute: 228,
                memory_utilization: 50,
                total_call_time_per_minute: 292
              }
            },
            {
              from: '2019-08-18T17:40:00+00:00',
              to: '2019-08-18T17:42:00+00:00',
              values: {
                cpu_utilization: 35,
                average_response_time: 59,
                average_value: 66,
                call_count: 3210,
                calls_per_minute: 383,
                max_response_time: 132,
                error_rate: 19,
                requests_per_minute: 332,
                memory_utilization: 68,
                total_call_time_per_minute: 391
              }
            },
            {
              from: '2019-08-18T17:38:00+00:00',
              to: '2019-08-18T17:40:00+00:00',
              values: {
                cpu_utilization: 38,
                average_response_time: 57,
                average_value: 76,
                call_count: 2023,
                calls_per_minute: 256,
                max_response_time: 150,
                error_rate: 16,
                requests_per_minute: 226,
                memory_utilization: 52,
                total_call_time_per_minute: 251
              }
            },
            {
              from: '2019-08-18T17:36:00+00:00',
              to: '2019-08-18T17:38:00+00:00',
              values: {
                cpu_utilization: 25,
                average_response_time: 52,
                average_value: 77,
                call_count: 3575,
                calls_per_minute: 392,
                max_response_time: 102,
                error_rate: 19,
                requests_per_minute: 341,
                memory_utilization: 57,
                total_call_time_per_minute: 201
              }
            },
            {
              from: '2019-08-18T17:34:00+00:00',
              to: '2019-08-18T17:36:00+00:00',
              values: {
                cpu_utilization: 35,
                average_response_time: 54,
                average_value: 61,
                call_count: 2427,
                calls_per_minute: 211,
                max_response_time: 113,
                error_rate: 15,
                requests_per_minute: 240,
                memory_utilization: 51,
                total_call_time_per_minute: 347
              }
            },
            {
              from: '2019-08-18T17:32:00+00:00',
              to: '2019-08-18T17:34:00+00:00',
              values: {
                cpu_utilization: 24,
                average_response_time: 65,
                average_value: 70,
                call_count: 3073,
                calls_per_minute: 374,
                max_response_time: 103,
                error_rate: 17,
                requests_per_minute: 271,
                memory_utilization: 66,
                total_call_time_per_minute: 267
              }
            },
            {
              from: '2019-08-18T17:30:00+00:00',
              to: '2019-08-18T17:32:00+00:00',
              values: {
                cpu_utilization: 20,
                average_response_time: 57,
                average_value: 70,
                call_count: 2950,
                calls_per_minute: 320,
                max_response_time: 117,
                error_rate: 17,
                requests_per_minute: 254,
                memory_utilization: 64,
                total_call_time_per_minute: 290
              }
            },
            {
              from: '2019-08-18T17:28:00+00:00',
              to: '2019-08-18T17:30:00+00:00',
              values: {
                cpu_utilization: 32,
                average_response_time: 78,
                average_value: 79,
                call_count: 3045,
                calls_per_minute: 219,
                max_response_time: 148,
                error_rate: 16,
                requests_per_minute: 276,
                memory_utilization: 51,
                total_call_time_per_minute: 373
              }
            },
            {
              from: '2019-08-18T17:26:00+00:00',
              to: '2019-08-18T17:28:00+00:00',
              values: {
                cpu_utilization: 39,
                average_response_time: 71,
                average_value: 55,
                call_count: 3276,
                calls_per_minute: 246,
                max_response_time: 111,
                error_rate: 15,
                requests_per_minute: 383,
                memory_utilization: 63,
                total_call_time_per_minute: 359
              }
            },
            {
              from: '2019-08-18T17:24:00+00:00',
              to: '2019-08-18T17:26:00+00:00',
              values: {
                cpu_utilization: 31,
                average_response_time: 74,
                average_value: 74,
                call_count: 3398,
                calls_per_minute: 359,
                max_response_time: 126,
                error_rate: 16,
                requests_per_minute: 289,
                memory_utilization: 65,
                total_call_time_per_minute: 214
              }
            },
            {
              from: '2019-08-18T17:22:00+00:00',
              to: '2019-08-18T17:24:00+00:00',
              values: {
                cpu_utilization: 21,
                average_response_time: 57,
                average_value: 78,
                call_count: 3588,
                calls_per_minute: 245,
                max_response_time: 107,
                error_rate: 20,
                requests_per_minute: 321,
                memory_utilization: 55,
                total_call_time_per_minute: 385
              }
            },
            {
              from: '2019-08-18T17:20:00+00:00',
              to: '2019-08-18T17:22:00+00:00',
              values: {
                cpu_utilization: 39,
                average_response_time: 67,
                average_value: 74,
                call_count: 3882,
                calls_per_minute: 313,
                max_response_time: 136,
                error_rate: 18,
                requests_per_minute: 220,
                memory_utilization: 62,
                total_call_time_per_minute: 277
              }
            },
            {
              from: '2019-08-18T17:18:00+00:00',
              to: '2019-08-18T17:20:00+00:00',
              values: {
                cpu_utilization: 24,
                average_response_time: 78,
                average_value: 69,
                call_count: 2539,
                calls_per_minute: 209,
                max_response_time: 116,
                error_rate: 15,
                requests_per_minute: 394,
                memory_utilization: 60,
                total_call_time_per_minute: 282
              }
            },
            {
              from: '2019-08-18T17:16:00+00:00',
              to: '2019-08-18T17:18:00+00:00',
              values: {
                cpu_utilization: 27,
                average_response_time: 80,
                average_value: 51,
                call_count: 3536,
                calls_per_minute: 376,
                max_response_time: 102,
                error_rate: 17,
                requests_per_minute: 252,
                memory_utilization: 69,
                total_call_time_per_minute: 273
              }
            },
            {
              from: '2019-08-18T17:14:00+00:00',
              to: '2019-08-18T17:16:00+00:00',
              values: {
                cpu_utilization: 23,
                average_response_time: 63,
                average_value: 79,
                call_count: 3754,
                calls_per_minute: 393,
                max_response_time: 122,
                error_rate: 16,
                requests_per_minute: 258,
                memory_utilization: 50,
                total_call_time_per_minute: 381
              }
            },
            {
              from: '2019-08-18T17:12:00+00:00',
              to: '2019-08-18T17:14:00+00:00',
              values: {
                cpu_utilization: 26,
                average_response_time: 54,
                average_value: 75,
                call_count: 3246,
                calls_per_minute: 331,
                max_response_time: 102,
                error_rate: 17,
                requests_per_minute: 290,
                memory_utilization: 57,
                total_call_time_per_minute: 373
              }
            },
            {
              from: '2019-08-18T16:12:00+00:00',
              to: '2019-08-18T16:12:00+00:00',
              values: {
                cpu_utilization: 34,
                average_response_time: 57,
                average_value: 62,
                call_count: 2868,
                calls_per_minute: 227,
                max_response_time: 137,
                error_rate: 17,
                requests_per_minute: 294,
                memory_utilization: 62,
                total_call_time_per_minute: 242
              }
            },
            {
              from: '2019-08-18T15:12:00+00:00',
              to: '2019-08-18T16:12:00+00:00',
              values: {
                cpu_utilization: 36,
                average_response_time: 54,
                average_value: 80,
                call_count: 3967,
                calls_per_minute: 221,
                max_response_time: 143,
                error_rate: 19,
                requests_per_minute: 243,
                memory_utilization: 55,
                total_call_time_per_minute: 237
              }
            },
            {
              from: '2019-08-18T14:12:00+00:00',
              to: '2019-08-18T15:12:00+00:00',
              values: {
                cpu_utilization: 22,
                average_response_time: 74,
                average_value: 72,
                call_count: 2648,
                calls_per_minute: 318,
                max_response_time: 117,
                error_rate: 15,
                requests_per_minute: 297,
                memory_utilization: 64,
                total_call_time_per_minute: 322
              }
            },
            {
              from: '2019-08-18T13:12:00+00:00',
              to: '2019-08-18T14:12:00+00:00',
              values: {
                cpu_utilization: 36,
                average_response_time: 58,
                average_value: 60,
                call_count: 3833,
                calls_per_minute: 326,
                max_response_time: 108,
                error_rate: 15,
                requests_per_minute: 268,
                memory_utilization: 51,
                total_call_time_per_minute: 379
              }
            },
            {
              from: '2019-08-18T12:12:00+00:00',
              to: '2019-08-18T13:12:00+00:00',
              values: {
                cpu_utilization: 40,
                average_response_time: 56,
                average_value: 56,
                call_count: 3659,
                calls_per_minute: 320,
                max_response_time: 131,
                error_rate: 18,
                requests_per_minute: 324,
                memory_utilization: 61,
                total_call_time_per_minute: 397
              }
            },
            {
              from: '2019-08-18T11:12:00+00:00',
              to: '2019-08-18T12:12:00+00:00',
              values: {
                cpu_utilization: 26,
                average_response_time: 64,
                average_value: 73,
                call_count: 3982,
                calls_per_minute: 362,
                max_response_time: 111,
                error_rate: 19,
                requests_per_minute: 305,
                memory_utilization: 64,
                total_call_time_per_minute: 280
              }
            },
            {
              from: '2019-08-18T10:12:00+00:00',
              to: '2019-08-18T11:12:00+00:00',
              values: {
                cpu_utilization: 37,
                average_response_time: 61,
                average_value: 67,
                call_count: 3130,
                calls_per_minute: 255,
                max_response_time: 119,
                error_rate: 15,
                requests_per_minute: 373,
                memory_utilization: 68,
                total_call_time_per_minute: 388
              }
            },
            {
              from: '2019-08-18T09:12:00+00:00',
              to: '2019-08-18T10:12:00+00:00',
              values: {
                cpu_utilization: 22,
                average_response_time: 79,
                average_value: 55,
                call_count: 2413,
                calls_per_minute: 252,
                max_response_time: 126,
                error_rate: 19,
                requests_per_minute: 269,
                memory_utilization: 55,
                total_call_time_per_minute: 372
              }
            },
            {
              from: '2019-08-18T08:12:00+00:00',
              to: '2019-08-18T09:12:00+00:00',
              values: {
                cpu_utilization: 37,
                average_response_time: 79,
                average_value: 51,
                call_count: 2980,
                calls_per_minute: 395,
                max_response_time: 134,
                error_rate: 17,
                requests_per_minute: 251,
                memory_utilization: 62,
                total_call_time_per_minute: 265
              }
            },
            {
              from: '2019-08-18T07:12:00+00:00',
              to: '2019-08-18T08:12:00+00:00',
              values: {
                cpu_utilization: 38,
                average_response_time: 61,
                average_value: 78,
                call_count: 2415,
                calls_per_minute: 347,
                max_response_time: 142,
                error_rate: 16,
                requests_per_minute: 381,
                memory_utilization: 55,
                total_call_time_per_minute: 346
              }
            },
            {
              from: '2019-08-18T06:12:00+00:00',
              to: '2019-08-18T07:12:00+00:00',
              values: {
                cpu_utilization: 30,
                average_response_time: 76,
                average_value: 76,
                call_count: 2959,
                calls_per_minute: 219,
                max_response_time: 128,
                error_rate: 18,
                requests_per_minute: 337,
                memory_utilization: 54,
                total_call_time_per_minute: 288
              }
            },
            {
              from: '2019-08-18T05:12:00+00:00',
              to: '2019-08-18T06:12:00+00:00',
              values: {
                cpu_utilization: 28,
                average_response_time: 71,
                average_value: 75,
                call_count: 3238,
                calls_per_minute: 335,
                max_response_time: 131,
                error_rate: 19,
                requests_per_minute: 260,
                memory_utilization: 54,
                total_call_time_per_minute: 383
              }
            },
            {
              from: '2019-08-18T04:12:00+00:00',
              to: '2019-08-18T05:12:00+00:00',
              values: {
                cpu_utilization: 37,
                average_response_time: 51,
                average_value: 56,
                call_count: 2571,
                calls_per_minute: 278,
                max_response_time: 145,
                error_rate: 16,
                requests_per_minute: 247,
                memory_utilization: 62,
                total_call_time_per_minute: 205
              }
            },
            {
              from: '2019-08-18T03:12:00+00:00',
              to: '2019-08-18T04:12:00+00:00',
              values: {
                cpu_utilization: 35,
                average_response_time: 54,
                average_value: 71,
                call_count: 2329,
                calls_per_minute: 356,
                max_response_time: 134,
                error_rate: 17,
                requests_per_minute: 378,
                memory_utilization: 53,
                total_call_time_per_minute: 245
              }
            },
            {
              from: '2019-08-18T02:12:00+00:00',
              to: '2019-08-18T03:12:00+00:00',
              values: {
                cpu_utilization: 22,
                average_response_time: 55,
                average_value: 79,
                call_count: 3136,
                calls_per_minute: 345,
                max_response_time: 129,
                error_rate: 20,
                requests_per_minute: 283,
                memory_utilization: 57,
                total_call_time_per_minute: 293
              }
            },
            {
              from: '2019-08-17T00:12:00+00:00',
              to: '2019-08-18T02:12:00+00:00',
              values: {
                cpu_utilization: 20,
                average_response_time: 70,
                average_value: 61,
                call_count: 3938,
                calls_per_minute: 224,
                max_response_time: 127,
                error_rate: 19,
                requests_per_minute: 375,
                memory_utilization: 52,
                total_call_time_per_minute: 259
              }
            },
            {
              from: '2019-08-16T00:12:00+00:00',
              to: '2019-08-17T00:12:00+00:00',
              values: {
                cpu_utilization: 21,
                average_response_time: 80,
                average_value: 65,
                call_count: 3963,
                calls_per_minute: 298,
                max_response_time: 104,
                error_rate: 18,
                requests_per_minute: 319,
                memory_utilization: 66,
                total_call_time_per_minute: 312
              }
            },
            {
              from: '2019-08-15T00:12:00+00:00',
              to: '2019-08-16T00:12:00+00:00',
              values: {
                cpu_utilization: 26,
                average_response_time: 77,
                average_value: 59,
                call_count: 3535,
                calls_per_minute: 222,
                max_response_time: 140,
                error_rate: 19,
                requests_per_minute: 269,
                memory_utilization: 70,
                total_call_time_per_minute: 348
              }
            },
            {
              from: '2019-08-14T00:12:00+00:00',
              to: '2019-08-15T00:12:00+00:00',
              values: {
                cpu_utilization: 38,
                average_response_time: 57,
                average_value: 75,
                call_count: 2293,
                calls_per_minute: 224,
                max_response_time: 106,
                error_rate: 18,
                requests_per_minute: 255,
                memory_utilization: 56,
                total_call_time_per_minute: 258
              }
            },
            {
              from: '2019-08-13T00:12:00+00:00',
              to: '2019-08-14T00:12:00+00:00',
              values: {
                cpu_utilization: 35,
                average_response_time: 75,
                average_value: 70,
                call_count: 2110,
                calls_per_minute: 207,
                max_response_time: 149,
                error_rate: 16,
                requests_per_minute: 260,
                memory_utilization: 59,
                total_call_time_per_minute: 397
              }
            },
            {
              from: '2019-08-12T00:12:00+00:00',
              to: '2019-08-13T00:12:00+00:00',
              values: {
                cpu_utilization: 25,
                average_response_time: 59,
                average_value: 65,
                call_count: 2877,
                calls_per_minute: 293,
                max_response_time: 134,
                error_rate: 20,
                requests_per_minute: 374,
                memory_utilization: 61,
                total_call_time_per_minute: 380
              }
            },
            {
              from: '2019-08-11T00:12:00+00:00',
              to: '2019-08-12T00:12:00+00:00',
              values: {
                cpu_utilization: 28,
                average_response_time: 66,
                average_value: 61,
                call_count: 2663,
                calls_per_minute: 389,
                max_response_time: 130,
                error_rate: 15,
                requests_per_minute: 217,
                memory_utilization: 53,
                total_call_time_per_minute: 240
              }
            },
            {
              from: '2019-08-10T00:12:00+00:00',
              to: '2019-08-11T00:12:00+00:00',
              values: {
                cpu_utilization: 34,
                average_response_time: 72,
                average_value: 71,
                call_count: 3226,
                calls_per_minute: 295,
                max_response_time: 150,
                error_rate: 17,
                requests_per_minute: 213,
                memory_utilization: 66,
                total_call_time_per_minute: 226
              }
            },
            {
              from: '2019-08-09T00:12:00+00:00',
              to: '2019-08-10T00:12:00+00:00',
              values: {
                cpu_utilization: 38,
                average_response_time: 77,
                average_value: 56,
                call_count: 3480,
                calls_per_minute: 358,
                max_response_time: 120,
                error_rate: 16,
                requests_per_minute: 310,
                memory_utilization: 67,
                total_call_time_per_minute: 346
              }
            },
            {
              from: '2019-08-08T00:12:00+00:00',
              to: '2019-08-09T00:12:00+00:00',
              values: {
                cpu_utilization: 25,
                average_response_time: 77,
                average_value: 58,
                call_count: 3602,
                calls_per_minute: 223,
                max_response_time: 113,
                error_rate: 19,
                requests_per_minute: 273,
                memory_utilization: 62,
                total_call_time_per_minute: 344
              }
            },
            {
              from: '2019-08-07T00:12:00+00:00',
              to: '2019-08-08T00:12:00+00:00',
              values: {
                cpu_utilization: 22,
                average_response_time: 72,
                average_value: 72,
                call_count: 3441,
                calls_per_minute: 245,
                max_response_time: 111,
                error_rate: 15,
                requests_per_minute: 219,
                memory_utilization: 67,
                total_call_time_per_minute: 307
              }
            },
            {
              from: '2019-08-06T00:12:00+00:00',
              to: '2019-08-07T00:12:00+00:00',
              values: {
                cpu_utilization: 21,
                average_response_time: 77,
                average_value: 64,
                call_count: 3403,
                calls_per_minute: 336,
                max_response_time: 112,
                error_rate: 19,
                requests_per_minute: 311,
                memory_utilization: 68,
                total_call_time_per_minute: 368
              }
            },
            {
              from: '2019-08-05T00:12:00+00:00',
              to: '2019-08-06T00:12:00+00:00',
              values: {
                cpu_utilization: 31,
                average_response_time: 66,
                average_value: 50,
                call_count: 2503,
                calls_per_minute: 201,
                max_response_time: 107,
                error_rate: 20,
                requests_per_minute: 330,
                memory_utilization: 62,
                total_call_time_per_minute: 378
              }
            },
            {
              from: '2019-08-04T00:12:00+00:00',
              to: '2019-08-05T00:12:00+00:00',
              values: {
                cpu_utilization: 33,
                average_response_time: 73,
                average_value: 69,
                call_count: 2261,
                calls_per_minute: 230,
                max_response_time: 113,
                error_rate: 18,
                requests_per_minute: 389,
                memory_utilization: 63,
                total_call_time_per_minute: 358
              }
            },
            {
              from: '2019-08-03T00:12:00+00:00',
              to: '2019-08-04T00:12:00+00:00',
              values: {
                cpu_utilization: 39,
                average_response_time: 60,
                average_value: 73,
                call_count: 2783,
                calls_per_minute: 277,
                max_response_time: 108,
                error_rate: 18,
                requests_per_minute: 389,
                memory_utilization: 61,
                total_call_time_per_minute: 299
              }
            },
            {
              from: '2019-08-02T00:12:00+00:00',
              to: '2019-08-03T00:12:00+00:00',
              values: {
                cpu_utilization: 23,
                average_response_time: 74,
                average_value: 73,
                call_count: 2835,
                calls_per_minute: 243,
                max_response_time: 121,
                error_rate: 17,
                requests_per_minute: 298,
                memory_utilization: 67,
                total_call_time_per_minute: 302
              }
            },
            {
              from: '2019-08-01T00:12:00+00:00',
              to: '2019-08-02T00:12:00+00:00',
              values: {
                cpu_utilization: 32,
                average_response_time: 57,
                average_value: 52,
                call_count: 3835,
                calls_per_minute: 268,
                max_response_time: 144,
                error_rate: 20,
                requests_per_minute: 299,
                memory_utilization: 67,
                total_call_time_per_minute: 302
              }
            },
            {
              from: '2019-07-31T00:12:00+00:00',
              to: '2019-08-01T00:12:00+00:00',
              values: {
                cpu_utilization: 30,
                average_response_time: 72,
                average_value: 58,
                call_count: 2609,
                calls_per_minute: 266,
                max_response_time: 142,
                error_rate: 20,
                requests_per_minute: 244,
                memory_utilization: 60,
                total_call_time_per_minute: 227
              }
            },
            {
              from: '2019-07-30T00:12:00+00:00',
              to: '2019-07-31T00:12:00+00:00',
              values: {
                cpu_utilization: 36,
                average_response_time: 56,
                average_value: 65,
                call_count: 2766,
                calls_per_minute: 398,
                max_response_time: 150,
                error_rate: 20,
                requests_per_minute: 266,
                memory_utilization: 70,
                total_call_time_per_minute: 335
              }
            },
            {
              from: '2019-07-29T00:12:00+00:00',
              to: '2019-07-30T00:12:00+00:00',
              values: {
                cpu_utilization: 33,
                average_response_time: 68,
                average_value: 55,
                call_count: 3259,
                calls_per_minute: 336,
                max_response_time: 121,
                error_rate: 16,
                requests_per_minute: 244,
                memory_utilization: 69,
                total_call_time_per_minute: 203
              }
            },
            {
              from: '2019-07-28T00:12:00+00:00',
              to: '2019-07-29T00:12:00+00:00',
              values: {
                cpu_utilization: 40,
                average_response_time: 80,
                average_value: 69,
                call_count: 2960,
                calls_per_minute: 385,
                max_response_time: 144,
                error_rate: 16,
                requests_per_minute: 381,
                memory_utilization: 70,
                total_call_time_per_minute: 235
              }
            },
            {
              from: '2019-07-27T00:12:00+00:00',
              to: '2019-07-28T00:12:00+00:00',
              values: {
                cpu_utilization: 40,
                average_response_time: 51,
                average_value: 64,
                call_count: 3362,
                calls_per_minute: 228,
                max_response_time: 142,
                error_rate: 19,
                requests_per_minute: 228,
                memory_utilization: 54,
                total_call_time_per_minute: 229
              }
            },
            {
              from: '2019-07-26T00:12:00+00:00',
              to: '2019-07-27T00:12:00+00:00',
              values: {
                cpu_utilization: 25,
                average_response_time: 73,
                average_value: 62,
                call_count: 3505,
                calls_per_minute: 298,
                max_response_time: 119,
                error_rate: 17,
                requests_per_minute: 278,
                memory_utilization: 62,
                total_call_time_per_minute: 265
              }
            },
            {
              from: '2019-07-25T00:12:00+00:00',
              to: '2019-07-26T00:12:00+00:00',
              values: {
                cpu_utilization: 23,
                average_response_time: 77,
                average_value: 61,
                call_count: 3498,
                calls_per_minute: 214,
                max_response_time: 107,
                error_rate: 19,
                requests_per_minute: 366,
                memory_utilization: 54,
                total_call_time_per_minute: 212
              }
            },
            {
              from: '2019-07-24T00:12:00+00:00',
              to: '2019-07-25T00:12:00+00:00',
              values: {
                cpu_utilization: 22,
                average_response_time: 52,
                average_value: 77,
                call_count: 2553,
                calls_per_minute: 317,
                max_response_time: 142,
                error_rate: 16,
                requests_per_minute: 259,
                memory_utilization: 58,
                total_call_time_per_minute: 398
              }
            },
            {
              from: '2019-07-23T00:12:00+00:00',
              to: '2019-07-24T00:12:00+00:00',
              values: {
                cpu_utilization: 25,
                average_response_time: 69,
                average_value: 62,
                call_count: 3743,
                calls_per_minute: 268,
                max_response_time: 116,
                error_rate: 19,
                requests_per_minute: 246,
                memory_utilization: 61,
                total_call_time_per_minute: 375
              }
            },
            {
              from: '2019-07-22T00:12:00+00:00',
              to: '2019-07-23T00:12:00+00:00',
              values: {
                cpu_utilization: 39,
                average_response_time: 77,
                average_value: 57,
                call_count: 3105,
                calls_per_minute: 351,
                max_response_time: 146,
                error_rate: 16,
                requests_per_minute: 233,
                memory_utilization: 69,
                total_call_time_per_minute: 282
              }
            },
            {
              from: '2019-07-21T00:12:00+00:00',
              to: '2019-07-22T00:12:00+00:00',
              values: {
                cpu_utilization: 31,
                average_response_time: 64,
                average_value: 63,
                call_count: 2780,
                calls_per_minute: 328,
                max_response_time: 135,
                error_rate: 19,
                requests_per_minute: 383,
                memory_utilization: 53,
                total_call_time_per_minute: 382
              }
            },
            {
              from: '2019-07-20T00:12:00+00:00',
              to: '2019-07-21T00:12:00+00:00',
              values: {
                cpu_utilization: 24,
                average_response_time: 62,
                average_value: 71,
                call_count: 2507,
                calls_per_minute: 387,
                max_response_time: 127,
                error_rate: 17,
                requests_per_minute: 340,
                memory_utilization: 59,
                total_call_time_per_minute: 283
              }
            }
          ]
        }
      ]
    }
  }
};

const fetchAppHealthMonitorDetails = (id, from, to) => async dispatch => {
  /*const response = await newRelic.get(
    '/applications/180066149/metrics/data.json?application_id=' +
      id +
      '&names[]=[]' +
      '&from=' +
      from +
      '&to=' +
      to
  );*/
  dispatch({
    type: 'APP_HEALTH_MONITOR_DETAILS',
    payload: mockMetricData
  });
};

export default fetchAppHealthMonitorDetails;

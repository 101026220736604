import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import fetchAppHealthMonitorDetails from '../../actions/fetchAppHealthMonitorDetails';
import GridContainer from '../../common/components/Grid/GridContainer.jsx';
import AppHealthChart from './AppHealthChart';
import DonutChart from './DonutChart';
import fetchAppStatus from '../../Panels/functions/fetchAppStatus';
import Button from '@material-ui/core/Button';
import '../../../assets/css/material-dashboard-react.css';

const AppHealthMonitorDetails = ({
  classes,
  appHealthMonitorDetails,
  appName,
  fetchAppHealthMonitorDetails,
  fetchAppStatus,
  appStatus
}) => {
  useEffect(() => {
    fetchAppHealthMonitorDetails(
      '180066149',
      '2019-08-08T18:10:00+00:00',
      '2019-08-08T18:40:00+00:00'
    );
  });

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button
          href="https://rpm.newrelic.com/accounts/2431182/applications/"
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          style={{
            backgroundColor: '#284860',
            color: 'white'
          }}
        >
          New Relic Dashboard
        </Button>
      </div>
      <div>
        <GridContainer>
          <AppHealthChart
            classes={classes}
            appHealthMonitorDetails={appHealthMonitorDetails}
            property="cpu_utilization"
            title={'CPU Utilization'}
            headerColor="info"
          />
          <AppHealthChart
            classes={classes}
            appHealthMonitorDetails={appHealthMonitorDetails}
            property="memory_utilization"
            title={'Memory Utilization'}
            headerColor="info"
          />
          <AppHealthChart
            classes={classes}
            appHealthMonitorDetails={appHealthMonitorDetails}
            property="error_rate"
            title={'Error Rate'}
            headerColor="info"
          />
          <AppHealthChart
            classes={classes}
            appHealthMonitorDetails={appHealthMonitorDetails}
            property="requests_per_minute"
            title={'Transactions Per Minute'}
            headerColor="info"
          />
          <DonutChart
            classes={classes}
            appHealthMonitorDetails={appHealthMonitorDetails}
            headerColor="info"
          />
        </GridContainer>
      </div>
    </div>
  );
};

export { AppHealthMonitorDetails };

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => {
    const appName = ownProps.match.params.appName;
    return {
      appName,
      appHealthMonitorDetails:
        state.appHealthMonitorDetails.data.metric_data.metrics[0].timeslices,
      appStatus: state.appstatus.data
    };
  };
  return mapStateToProps;
};

const connected = connect(
  makeMapStateToProps,
  { fetchAppHealthMonitorDetails, fetchAppStatus }
)(AppHealthMonitorDetails);

export default withStyles(dashboardStyle)(connected);

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import AdminNavbarLinks from './AdminNavbarLinks.jsx';
import RTLNavbarLinks from './RTLNavbarLinks.jsx';

import headerStyle from 'assets/jss/material-dashboard-react/components/headerStyle.jsx';
import Button from '../CustomButtons/Button.jsx';
import { PROFILES } from '../../../constants/userProfileConstants';

function Header({ ...props }) {
  const { classes, color, match, userProfileId, cart } = props;
  function makeBrand(userProfileId) {
    var name;
    props.routes.map((prop, key) => {
      const path = prop.path.split(':');
      const pathVariable = match.params.appName;
      if (props.location.pathname.includes(prop.layout + path[0])) {
        const brandName =
          pathVariable != null
            ? `${prop.name} (${pathVariable})`
            : prop.name === 'Dashboard'
            ? PROFILES[userProfileId]
            : prop.name;
        name = props.rtlActive ? prop.rtlName : brandName;
      }
      return null;
    });
    return name;
  }
  function useRole() {
    var name;
    props.routes.map((prop, key) => {
      const path = prop.path.split(':');
      if (props.location.pathname.includes(prop.layout + path[0])) {
        const brandName = prop.name === 'Dashboard' ? '(Role)' : null;
        name = props.rtlActive ? prop.rtlName : brandName;
      }
      return null;
    });
    return name;
  }

  const appBarClasses = classNames({
    [' ' + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button color="transparent" href="#" className={classes.title}>
            <span style={{ fontWeight: 'bold' }}>
              {makeBrand(userProfileId)}
            </span>
            {useRole()}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          {props.rtlActive ? (
            <RTLNavbarLinks />
          ) : (
            <AdminNavbarLinks userProfileId={userProfileId} cart={cart} />
          )}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger'])
};

export default withStyles(headerStyle)(Header);

import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../common/components/Grid/GridItem.jsx';
import GridContainer from '../../common/components/Grid/GridContainer.jsx';
import ArticleSearch from '../../Dashboard/components/ArticleSearch';

const styles = {};

function TableList(props) {
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <ArticleSearch classes={props.classes} />
        </GridItem>
      </GridContainer>
    </>
  );
}

export default withStyles(styles)(TableList);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import SystemAvailability from '../../Panels/SystemAvailability';
import SystemValidation from '../../Panels/SystemValidation';
import WorkflowSummary from '../../Panels/WorkflowSummary';

// @material-ui/icons
// the next two lines were in the original cce dashboard but removed b/c they are unused currently
// left them in here in case they are needed as these tend to be ubiquitous framework
// import Store from '@material-ui/icons/Store';
// import DateRange from '@material-ui/icons/DateRange';
// core components
import GridItem from '../../common/components/Grid/GridItem.jsx';
import GridContainer from '../../common/components/Grid/GridContainer.jsx';
import fetchAppStatus from '../../Panels/functions/fetchAppStatus';
import fetchSystemDetails from '../../Panels/functions/fetchSystemDetails';
import fetchValidationDetails from '../../Panels/functions/fetchValidationDetails';

import fetchValidationBreakdown from '../../actions/fetchValidationBreakdown';
import fetchValidationLogs from '../../actions/fetchValidationLogs';
import fetchStatusBreakdown from '../../actions/fetchStatusBreakdown';
import fetchProjectStatusCategoryCounts from '../../actions/fetchProjectStatusCategoryCounts';
import { costTrackerDataRows } from '../../actions/mockCostData';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import AllAppsMigrationStatusChart from './AllAppsMigrationStatusChart';
import AllAppsReleaseChart from './AllAppsReleaseChart';
import AppIssues from '../../Panels/AppIssues';
import fetchIncidentResponseTime from '../../actions/mockIncidentResponseTime';
import fetchAppsInPriorityQueue from '../../actions/fetchAppsInPriorityQueue';
import fetchAppHealthMonitorDetails from '../../actions/fetchAppHealthMonitorDetails';
import fetchMigrationCostScheduleTrackerData from '../../actions/fetchMigrationCostScheduleTrackerData';
import fetchMigrationSecurityStatus from '../../actions/fetchMigrationSecurityStatus';
import fetchWorkflowSummary from '../../actions/fetchWorkflowSummary';
import Financial from './Financial';
import CyberReadiness from './CyberReadiness';
import fetchAppIssues from '../../Panels/functions/fetchAppIssues';
import fetchAllAppsReleaseChart from '../../Panels/functions/fetchAllAppsReleaseChart';
import Concepts from './Concepts';
import Contacts from './Contacts';

class Dashboard extends React.Component {
  state = {
    value: 0
  };

  componentDidMount = () => {
    this.props.fetchProjectStatusCategoryCounts();
    this.props.costTrackerDataRows();
    this.props.fetchIncidentResponseTime();
    this.props.fetchAppsInPriorityQueue();
    this.props.fetchMigrationCostScheduleTrackerData();
    this.props.fetchMigrationSecurityStatus();
    this.props.fetchAppStatus();
    this.props.fetchStatusBreakdown();
    this.props.fetchSystemDetails();
    this.props.fetchValidationDetails();
    this.props.fetchAppIssues();
    this.props.fetchAllAppsReleaseChart();
    this.props.fetchAppHealthMonitorDetails(
      '180066149',
      '2019-08-08T18:10:00+00:00',
      '2019-08-08T18:40:00+00:00'
    );
    this.props.fetchValidationBreakdown();
    this.props.fetchWorkflowSummary();

    //Autorefresh APIs
    this.interval = setInterval(() => {
      //New Relic API calls
      this.props.fetchStatusBreakdown();
      this.props.fetchValidationBreakdown();
      this.props.fetchSystemDetails();
      //Jira API calls
      this.props.fetchAppIssues();
      this.props.fetchValidationDetails();

      this.props.fetchWorkflowSummary();
    }, 300000);
    this.props.fetchAllAppsReleaseChart();
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const {
      classes,
      projectStatusCategoryCounts,
      fetchProjectStatusCategoryCounts
    } = this.props;
    return (
      <div>
        <Paper component="h4" elevation={3} className={classes.paperWrapper}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <CyberReadiness classes={classes} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Financial classes={classes} />
            </GridItem>
          </GridContainer>
        </Paper>
        <Paper component="h4" elevation={3} className={classes.paperWrapper}>
          &nbsp; &nbsp;{<b>System Health</b>}
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <SystemAvailability footerClass={classes.stats} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <SystemValidation footerClass={classes.stats} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <WorkflowSummary footerClass={classes.stats} />
            </GridItem>
          </GridContainer>
        </Paper>
        <Paper component="h4" elevation={3} className={classes.paperWrapper}>
          &nbsp; &nbsp;{<b>Application Development Status</b>}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <AllAppsMigrationStatusChart
                counts={projectStatusCategoryCounts}
                fetchProjectStatusCategoryCounts={
                  fetchProjectStatusCategoryCounts
                }
                footerClass={classes.stats}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <AllAppsReleaseChart footerClass={classes.stats} />
            </GridItem>
            <AppIssues footerClass={classes.stats} />
          </GridContainer>
        </Paper>
        <Paper component="h4" elevation={3} className={classes.paperWrapper}>
          &nbsp; &nbsp;{<b>Advisory Council Insights</b>}
          <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
              <Concepts classes={classes} />
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <Contacts classes={classes} />
            </GridItem>
          </GridContainer>
        </Paper>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  statuses: PropTypes.object,
  projectStatusCategoryCounts: PropTypes.object,
  costTrackerData: PropTypes.array,
  incidentResponseTime: PropTypes.string,
  appsInPriorityQueue: PropTypes.array,
  migrationSecurityStatus: PropTypes.array
};

const mapStateToProps = state => {
  return {
    projectStatusCategoryCounts: state.projectStatusCategoryCounts,
    costTrackerData: state.costTracker,
    incidentResponseTime: state.incidentResponseTime,
    appsInPriorityQueue: state.appsInPriorityQueue,
    migrationCostScheduleTrackerData: state.migrationCostScheduleTrackerData,
    migrationSecurityStatus: state.migrationSecurityStatus
  };
};

export default connect(
  mapStateToProps,
  {
    fetchProjectStatusCategoryCounts,
    costTrackerDataRows,
    fetchIncidentResponseTime,
    fetchAppsInPriorityQueue,
    fetchMigrationCostScheduleTrackerData,
    fetchMigrationSecurityStatus,
    fetchAppStatus,
    fetchSystemDetails,
    fetchStatusBreakdown,
    fetchAppIssues,
    fetchAppHealthMonitorDetails,
    fetchValidationBreakdown,
    fetchValidationDetails,
    fetchValidationLogs,
    fetchAllAppsReleaseChart,
    fetchWorkflowSummary
  }
)(withStyles(dashboardStyle)(Dashboard));

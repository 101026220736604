import jira from '../../apis/jira';
import {
  FETCH_STATUSES_STARTED,
  FETCH_STATUSES_FAILED,
  projects
} from '../../constants/chartConstants';

const fetchAppIssues = () => async dispatch => {
  dispatch({ type: FETCH_STATUSES_STARTED });
  try {
    const projectKeys = Object.keys(projects);
    const response = await jira.get(
      `/search?jql=project in (${projectKeys}) AND Priority = 1`
    );
    const statuses = {
      data: []
    };
    response.data.issues.forEach(issue => {
      var assignId = '';
      var isOpen = false;
      var currentTime = new Date();
      if (issue.fields.assignee) {
        assignId = new Date(issue.fields.created)
          .toISOString()
          .substring(0, 10);
      }
      if (issue.fields.status.statusCategory.key !== 'done') {
        isOpen = true;
      }
      var object = {
        applicationName: issue.key,
        description: issue.fields.summary,
        severity: issue.fields.priority.name,
        status: issue.fields.status.name,
        assignedTo: assignId,
        isOpen: isOpen,
        timestamp: currentTime
      };
      statuses.data.push(object);
    });
    dispatch({ type: 'APPISSUE', payload: statuses });
  } catch (error) {
    dispatch({
      type: FETCH_STATUSES_FAILED,
      payload: error.response ? error.response.data.errorMessages : [error]
    });
  }
};

export default fetchAppIssues;

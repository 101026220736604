import React from 'react';
import Card from '../../common/components/Card/Card.jsx';
import CardHeader from '../../common/components/Card/CardHeader.jsx';
import CardBody from '../../common/components/Card/CardBody.jsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  cardHeader: {
    padding: '15px',
    marginTop: '-20px',
    borderRadius: '3px',
    background: '#465337',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4)',
    color: '#FFF',
    margin: '0 15px',
    position: 'relative',
    zIndex: '3 !important',
    borderBottom: 'none',
    display: 'flex'
  },
  titleSection: {
    height: '20px',
    float: 'left',
    flex: 8
  },
  buttonContainer: {
    display: 'flex',
    flex: 1
  },
  cardTitle: {
    marginTop: '0px',
    minHeight: 'auto',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardTitleWhite: {
    color: 'white',
    margin: '0',
    lineHeight: '1',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: 'gray',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  cardCategory: {
    color: '#edaaa5',
    margin: 0,
    marginTop: 0,
    marginBottom: 0
  },
  card: {
    maxWidth: 400
  },
  cardBody: {
    height: 800
  },
  button: {
    backgroundColor: '#6F8753',
    color: 'white',
    margin: '2%',
    flex: 1
  },
  successText: {
    color: 'green'
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px'
  },
  stats: {
    color: 'gray',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    }
  },
  cardCategoryWhite: {
    color: 'white',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  axisTitle: {
    fill: 'white',
    fillOpacity: 0.8
  },
  paperWrapper: {
    backgroundColor: '#fafafa',
    [theme.breakpoints.up('md')]: {
      padding: '10px'
    },
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none'
    },
    padding: '10px 0px'
  },
  tableCell: {
    paddingRight: '14px'
  }
});

const Contacts = ({ classes }) => {
  return (
    <Card>
      <CardHeader color="success">
        <h4 className={classes.cardTitleWhite}>Advisory Council</h4>
      </CardHeader>
      <CardBody>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              <TableCell className={classes.tableHeadCell}>Expertise</TableCell>
              <TableCell className={classes.tableHeadCell}>
                Contact Name
              </TableCell>
              <TableCell className={classes.tableHeadCell}>E-Mail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                Software Development
              </TableCell>
              <TableCell className={classes.noWrap}>Bob Ritchie</TableCell>
              <TableCell className={classes.noWrap}>
                <a href="mailto:robert.d.ritchie@saic.com">
                  robert.d.ritchie@saic.com
                </a>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                Business Intelligence, Data Analytics, and Data Sciences
              </TableCell>
              <TableCell className={classes.noWrap}>Mini Kanwal</TableCell>
              <TableCell className={classes.noWrap}>
                <a href="mailto:mini.kanwal@saic.com">mini.kanwal@saic.com</a>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                Data Analytics and Data Sciences
              </TableCell>
              <TableCell className={classes.noWrap}>Sanjay Sardar</TableCell>
              <TableCell className={classes.noWrap}>
                <a href="mailto:sanjay.sardar@saic.com">
                  sanjay.sardar@saic.com
                </a>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                Cloud Technologies, Transformation, and Infrastructures
              </TableCell>
              <TableCell className={classes.noWrap}>Coby Holloway</TableCell>
              <TableCell className={classes.noWrap}>
                <a href="mailto:marshall.c.holloway@saic.com">
                  marshall.c.holloway@saic.com
                </a>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                Agile Transformations, Software Development, and Data Migration
              </TableCell>
              <TableCell className={classes.noWrap}>Susan Myers</TableCell>
              <TableCell className={classes.noWrap}>
                <a href="mailto:susan.c.myers@saic.com">
                  susan.c.myers@saic.com
                </a>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                Containerization, Infrastructure, and Enterprise Services
              </TableCell>
              <TableCell className={classes.noWrap}>Carsten Schmid</TableCell>
              <TableCell className={classes.noWrap}>
                <a href="mailto:carsten.r.schmid@saic.com">
                  carsten.r.schmid@saic.com
                </a>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                Cloud Native Software Development
              </TableCell>
              <TableCell className={classes.noWrap + ' ' + classes.tableCell}>
                Fazal Mohammed
              </TableCell>
              <TableCell className={classes.noWrap}>
                <a href="mailto:fazal.mohammed@saic.com">
                  fazal.mohammed@saic.com
                </a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default withStyles(styles)(Contacts);

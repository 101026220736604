const costTracker = [
  {
    applicationName: 'AF App 3',
    estCurrentCost: '$22,156',
    previousCost: '$19,342',
    avgMonthlyCost: '$19,342',
    billingCycleEnds: '1 day',
    cloudServiceProvider: 'Amazon Web Services'
  },
  {
    applicationName: 'AF App 4',
    estCurrentCost: '$1,043',
    previousCost: '$1,126',
    avgMonthlyCost: '$2,253',
    billingCycleEnds: '14 days',
    cloudServiceProvider: 'Microsoft Azure'
  },
  {
    applicationName: 'AF App 5',
    estCurrentCost: '$12,105',
    previousCost: '$12,240',
    avgMonthlyCost: '$12,650',
    billingCycleEnds: '1 day',
    cloudServiceProvider: 'Amazon Web Services'
  }
];

const costTrackerDataRows = () => dispatch => {
  const dataRows = [];
  costTracker.forEach((item, i) => {
    dataRows.push([
      item.applicationName,
      item.estCurrentCost,
      item.avgMonthlyCost,
      item.billingCycleEnds,
      item.cloudServiceProvider
    ]);
  });
  dispatch({ type: 'COST_TRACKER', payload: dataRows });
};

export { costTracker, costTrackerDataRows };

import { ADD_ITEM_TO_CART } from '../actions/cartActions';

const cartReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      return Object.assign([], [...state, action.payload]);
    default:
      return state;
  }
};

export default cartReducer;

const migrationSecurityStatus = [
  {
    applicationName: 'AF App 10',
    issm: 'C.McCoy',
    aodr: 'J.Dubs',
    eMassID: '123456789',
    atoType: 'Full ATO',
    securityArtifacts: 'Done',
    stigs: {
      reviewedStatus: '784 of 968 reviewed',
      issues: '12 Open CAT I'
    },
    securityControls: {
      coverage: '290 of 307 Covered'
    },
    nessusScans: {
      sanDate: '24 May 2019',
      scanResult: '2 Vulnerabilities'
    },
    poaAndMs: {
      high: '1',
      med: '3',
      low: '4'
    }
  },
  {
    applicationName: 'AF App 11',
    issm: 'J.Mason',
    aodr: 'M.Murray',
    eMassID: '2344767890',
    atoType: 'Assess Only',
    securityArtifacts: 'blocked',
    stigs: {
      reviewedStatus: '97 of 158 reviewed',
      issues: '3 Open CAT I'
    },
    securityControls: {
      coverage: '119 of 563 Covered'
    },
    nessusScans: {
      sanDate: '24 May 2019',
      scanResult: 'Clean'
    },
    poaAndMs: {
      high: '0',
      med: '5',
      low: '2'
    }
  },
  {
    applicationName: 'AF App 12',
    issm: null,
    stigs: {
      reviewedStatus: '',
      issues: ''
    },
    securityControls: {
      coverage: ''
    },
    nessusScans: {
      sanDate: '',
      scanResult: ''
    },
    poaAndMs: {
      high: '',
      med: '',
      low: ''
    }
  }
];

const fetchMigrationSecurityStatus = () => dispatch => {
  dispatch({
    type: 'MIGRATION_SECURITY_STATUS',
    payload: migrationSecurityStatus
  });
};

export default fetchMigrationSecurityStatus;

import * as types from '../constants/ajaxActionTypes';

export function beginAjaxCall(feature) {
  return { type: types.BEGIN_AJAX_CALL, payload: feature };
}

export function endAjaxCall(feature) {
  return { type: types.END_AJAX_CALL, payload: feature };
}

export function ajaxCallError(feature, error) {
  return { type: types.AJAX_CALL_ERROR, payload: { feature, error } };
}

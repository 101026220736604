import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

const styles = theme => ({
  tableHeadCell: {
    color: '#6F8753',
    whiteSpace: 'nowrap'
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  tableCell: {
    //padding: '0'
    paddingLeft: '0',
    paddingRight: '2px'
  }
});

const AppReleaseTable = ({ classes, tableHead, tableData }) => {
  const tableRows = tableData.appReleaseChart.map((prop, key) => {
    return tableData.status === prop.isOpen ? (
      <TableRow key={prop.applicationName} className={classes.tableRow}>
        <TableCell className={classes.tableCell}>
          {prop.applicationName}
        </TableCell>
        <TableCell className={classes.tableCell}>{prop.description}</TableCell>
        <TableCell className={classes.tableCell}>{prop.severity}</TableCell>
      </TableRow>
    ) : null;
  });

  return (
    <div className={classes.tableResponsive}>
      <Table>
        {tableHead !== undefined ? (
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{tableRows}</TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(AppReleaseTable);

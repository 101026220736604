import { combineReducers } from 'redux';
import fetchAppStatusReducer from './fetchAppStatusReducer';
import fetchSystemDetailReducer from './fetchSystemDetailReducer';
import fetchValidationDetailReducer from './fetchValidationDetailReducer';
import fetchStatusBreakdownReducer from './fetchStatusBreakdownReducer';
import fetchValidationBreakdownReducer from './fetchValidationBreakdownReducer';
import fetchValidationLogsReducer from './fetchValidationLogsReducer';
import issueStatusesReducer from './issueStatusesReducer';
import projectStatusCategoryCountsReducer from './projectStatusCategoryCountsReducer';
import servicesReducer from '../features/services/reducers/servicesReducer';
import costTrackerReducer from './costTrackerReducer';
import appCostTrackerReducer from './appCostTrackerReducer';
import fetchAppIssueReducer from './fetchAppIssueReducer';
import appHealthMonitorDetailsReducer from './appHealthMonitorDetailsReducer';
import fetchAppReleaseChartReducer from './fetchAppReleaseChartReducer';
import {
  FETCH_STATUSES_STARTED,
  FETCH_STATUSES_FINISHED,
  FETCH_STATUSES_FAILED,
  FETCH_ANALYSIS_STATUSES_STARTED,
  FETCH_ANALYSIS_STATUSES_FINISHED,
  FETCH_ANALYSIS_STATUSES_FAILED
} from '../features/constants/chartConstants';
import {
  FETCH_ARTICLES_FAILED,
  FETCH_ARTICLES_STARTED,
  FETCH_ARTICLES_FINISHED,
  FETCH_FEATURED_ARTICLES_FAILED,
  FETCH_FEATURED_ARTICLES_STARTED,
  FETCH_FEATURED_ARTICLES_FINISHED
} from '../features/constants/articleSearchConstants';
import incidentResponseTimeReducer from './incidentResponseTimeReducer.js';
import userProfileReducer from './userProfileReducer';
import appsInPriorityQueueReducer from './appsInPriorityQueueReducer';
import articlesReducer from './articlesReducer';
import featuredArticlesReducer from './featuredArticlesReducer';
import migrationCostScheduleTrackerReducer from './migrationCostScheduleTrackerReducer';
import cartReducer from '../features/cart/reducers/cartReducer';
import migrationSecurityStatusReducer from './migrationSecurityStatusReducer';
import fetchWorkflowSummaryReducer from './fetchWorkflowSummaryReducer';

export default combineReducers({
  statuses: issueStatusesReducer(
    FETCH_STATUSES_STARTED,
    FETCH_STATUSES_FINISHED,
    FETCH_STATUSES_FAILED
  ),
  analysisIssueStatuses: issueStatusesReducer(
    FETCH_ANALYSIS_STATUSES_STARTED,
    FETCH_ANALYSIS_STATUSES_FINISHED,
    FETCH_ANALYSIS_STATUSES_FAILED
  ),
  userProfileId: userProfileReducer,
  projectStatusCategoryCounts: projectStatusCategoryCountsReducer,
  services: servicesReducer,
  costTracker: costTrackerReducer,
  appstatus: fetchAppStatusReducer,
  systemdetail: fetchSystemDetailReducer,
  validationdetail: fetchValidationDetailReducer,
  statusbreakdown: fetchStatusBreakdownReducer,
  validationBreakdown: fetchValidationBreakdownReducer,
  validationLogs: fetchValidationLogsReducer,
  appIssues: fetchAppIssueReducer,
  appReleaseChart: fetchAppReleaseChartReducer,
  appCostTracker: appCostTrackerReducer,
  appHealthMonitorDetails: appHealthMonitorDetailsReducer,
  incidentResponseTime: incidentResponseTimeReducer,
  appsInPriorityQueue: appsInPriorityQueueReducer,
  articles: articlesReducer(
    FETCH_ARTICLES_STARTED,
    FETCH_ARTICLES_FINISHED,
    FETCH_ARTICLES_FAILED
  ),
  featuredArticles: featuredArticlesReducer(
    FETCH_FEATURED_ARTICLES_STARTED,
    FETCH_FEATURED_ARTICLES_FINISHED,
    FETCH_FEATURED_ARTICLES_FAILED
  ),
  migrationCostScheduleTrackerData: migrationCostScheduleTrackerReducer,
  cart: cartReducer,
  migrationSecurityStatus: migrationSecurityStatusReducer,
  workflowSummary: fetchWorkflowSummaryReducer
});

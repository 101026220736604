import React, { useMemo } from 'react';
import _ from 'lodash';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.08)'
    },
    '& span': {
      color: 'rgba(0,0,255,0.87)'
    }
  }
};

const getListItems = (articles, classes) => {
  return _.map(articles, (article, index) => {
    return (
      <ListItem
        component="a"
        key={`article-list-item-${index}`}
        href={article.link}
        target="_blank"
        className={classes.listItem}
      >
        <ListItemText primary={article.title} />
      </ListItem>
    );
  });
};

const ArticleList = ({ articles, classes }) => {
  return useMemo(() => getListItems(articles, classes), [articles, classes]);
};

export default withStyles(styles)(ArticleList);

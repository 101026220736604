import React from 'react';
import { connect } from 'react-redux';
import Card from '../../common/components/Card/Card.jsx';
import CardHeader from '../../common/components/Card/CardHeader.jsx';
import CardBody from '../../common/components/Card/CardBody.jsx';
import Grid from '@material-ui/core/Grid';
import Spinner from './Spinner';
import ArticleSearchBar from './ArticleSearchBar';
import ArticleList from './ArticleList';
import { fetchArticles } from '../../actions/fetchArticles';

class ArticleSearch extends React.Component {
  state = {
    searchSubmitted: false
  };

  onSubmit = term => {
    this.props.fetchArticles(term);
    this.setState(state => ({ searchSubmitted: true }));
  };

  render() {
    const { classes, articles } = this.props;
    const { searchSubmitted } = this.state;
    const showError = articles.meta.errorMessage !== '';
    const showSpinner = !showError && articles.meta.fetching;
    const showArticles =
      !showError && !showSpinner && !articles.meta.fetching && searchSubmitted;
    const hasArticles = articles.results.length !== 0;
    return (
      <Card>
        <CardHeader color="success">
          <h4 className={classes.cardTitleWhite}>Search Knowledge Base</h4>
        </CardHeader>

        <CardBody>
          <ArticleSearchBar onSubmit={this.onSubmit} classes={classes} />

          <Grid container>
            <Grid item xs={12} md={12}>
              <div className={classes.demo}>
                {showError && <h4>Error retrieving data from Confluence</h4>}
                {showSpinner && <Spinner color="#000000" />}
                {showArticles && !hasArticles && <h4>No Articles found.</h4>}
                {showArticles && hasArticles && (
                  <ArticleList articles={articles.results} />
                )}
              </div>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    articles: state.articles
  };
};

export default connect(
  mapStateToProps,
  {
    fetchArticles
  }
)(ArticleSearch);

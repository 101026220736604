//import newRelic from '../apis/newRelic';

const mockLogs = [
  {
    AppName: 'App 1',
    Logs:
      'SOAP/REST/RPC call A <br />SOAP/REST/RPC call B <br />Test queries against DB1 <br />Test queries against DB2 <br />Test queries against DB3 <br />Test queries against DB4 <br />Test queries against DB5 <br />Test queries against DB6'
  },
  {
    AppName: 'App 2',
    Logs:
      'SOAP/REST/RPC call A <br />SOAP/REST/RPC call B <br />Test queries against DB1 <br />Test queries against DB2 <br />Test queries against DB3 <br />Test queries against DB4 <br />Test queries against DB5 <br />Test queries against DB6'
  },
  {
    AppName: 'App 3',
    Logs:
      'SOAP/REST/RPC call A <br />SOAP/REST/RPC call B <br />Test queries against DB1 <br />Test queries against DB2 <br />Test queries against DB3 <br />Test queries against DB4 <br />Test queries against DB5 <br />Test queries against DB6'
  },
  {
    AppName: 'App 4',
    Logs:
      'SOAP/REST/RPC call A <br />SOAP/REST/RPC call B <br />Test queries against DB1 <br />Test queries against DB2 <br />Test queries against DB3 <br />Test queries against DB4 <br />Test queries against DB5 <br />Test queries against DB6'
  },
  {
    AppName: 'App 5',
    Logs:
      'SOAP/REST/RPC call A <br />SOAP/REST/RPC call B <br />Test queries against DB1 <br />Test queries against DB2 <br />Test queries against DB3 <br />Test queries against DB4 <br />Test queries against DB5 <br />Test queries against DB6'
  },
  {
    AppName: 'App 1',
    Logs:
      'SOAP/REST/RPC call A <br />SOAP/REST/RPC call B <br />Test queries against DB1 <br />Test queries against DB2 <br />Test queries against DB3 <br />Test queries against DB4 <br />Test queries against DB5 <br />Test queries against DB6'
  },
  {
    AppName: 'App 6',
    Logs:
      'SOAP/REST/RPC call A <br />SOAP/REST/RPC call B <br />Test queries against DB1 <br />Test queries against DB2 <br />Test queries against DB3 <br />Test queries against DB4 <br />Test queries against DB5 <br />Test queries against DB6'
  }
];

const fetchAppHealthMonitorDetails = appName => async dispatch => {
  var filteredLog = '';
  mockLogs.forEach(app => {
    if (app.AppName === appName) {
      filteredLog = filteredLog + app.Logs + '<br><br>';
    }
  });
  if (filteredLog === '') {
    filteredLog = filteredLog + mockLogs[1].Logs + '<br><br>';
  }
  dispatch({
    type: 'VALIDATION_LOGS',
    payload: { __html: filteredLog }
  });
};

export default fetchAppHealthMonitorDetails;

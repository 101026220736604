import React from 'react';
import { makeStyles } from '@material-ui/styles';
import GridItem from '../../common/components/Grid/GridItem.jsx';
import GridContainer from '../../common/components/Grid/GridContainer.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  progress: {
    color: props => (props.color ? props.color : '#ffffff')
  }
});

const Spinner = props => {
  const classes = useStyles(props);
  return (
    <GridContainer
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <GridItem xs={3}>
        <CircularProgress className={classes.progress} />
      </GridItem>
    </GridContainer>
  );
};

export default Spinner;

import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import readAllServices from './features/services/actions/readAllServices.js';

const DataFetcher = ({ actions }) => {
  useEffect(() => {
    actions.readAllServices();
  }, [actions]);
  return null;
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ readAllServices }, dispatch)
  };
};

export default connect(
  null,
  mapDispatchToProps
)(DataFetcher);

import React, { useState } from 'react';
import Card from '../../common/components/Card/Card.jsx';
import CardHeader from '../../common/components/Card/CardHeader.jsx';
import CardBody from '../../common/components/Card/CardBody.jsx';
import GridItem from '../../common/components/Grid/GridItem.jsx';
import CardFooter from '../../common/components/Card/CardFooter.jsx';
import createChartData from '../functions/createDonutChartData';

import { Doughnut } from 'react-chartjs-2';

const staticOptions = {
  title: {
    display: true,
    fontFamily: 'Roboto',
    fontSize: 20
  },
  legend: {
    position: 'right'
  }
};

const DonutChart = ({ appHealthMonitorDetails, classes, headerColor }) => {
  const [duration] = useState('hour');
  const { dataLine } = createChartData(duration, appHealthMonitorDetails || []);
  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card>
        <CardHeader className={classes.cardHeader} color={headerColor}>
          <h4 className={classes.cardTitleWhite}>
            Response Codes
            <span className={classes.cardCategory}> Last 12 Hours</span>
          </h4>
        </CardHeader>
        <CardBody />
        <Doughnut data={dataLine} options={staticOptions} />
        <CardFooter />
      </Card>
    </GridItem>
  );
};

export default DonutChart;

import jira from '../apis/jira';
import _ from 'lodash';
import {
  FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FAILED,
  FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FINISHED,
  FETCH_PROJECT_STATUS_CATEGORY_COUNTS_STARTED,
  projects
} from '../constants/chartConstants';

const STATUSES_TO_IGNORE = ['No Category'];

const fetchProjectIssueStatuses = () => async dispatch => {
  dispatch({ type: FETCH_PROJECT_STATUS_CATEGORY_COUNTS_STARTED });
  try {
    const projectKeys = Object.keys(projects);
    const statusCategoriesResponse = await jira.get('statuscategory');
    const statuses = {};

    for (const projectKey of projectKeys) {
      for (const statusCategory of statusCategoriesResponse.data) {
        if (_.indexOf(STATUSES_TO_IGNORE, statusCategory.name) === -1) {
          statuses[`${projectKey}${statusCategory.id}`] = {
            projectKey,
            projectName: projects[projectKey],
            categoryName: statusCategory.name,
            categoryId: statusCategory.id
          };
        }
      }
    }
    const allStatuses = await Promise.all(
      _.map(
        statuses,
        ({ projectKey, projectName, categoryId, categoryName }) =>
          new Promise(resolve => {
            jira
              .get(
                `/search?jql=project%20%3D%20${projectKey}%20AND%20statusCategory%20%3D%20${categoryId}`
              )
              .then(result => {
                resolve({
                  projectName,
                  projectKey,
                  categoryName,
                  count: result.data.total
                });
              });
          })
      )
    );

    dispatch({
      type: FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FINISHED,
      payload: allStatuses
    });
  } catch (error) {
    dispatch({
      type: FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FAILED,
      payload: error.response ? error.response.data.errorMessages : [error]
    });
  }
};

export default fetchProjectIssueStatuses;

import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// core components
import Icon from '@material-ui/core/Icon';
import Visibility from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  tableHeadCell: {
    color: '#284860'
  },
  tableResponsive: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  }
});

const CustomTable = ({ classes, tableHead, tableData, tableFilter }) => {
  const healthStatusToColor = {
    Healthy: '#4caf50',
    Warning: 'orange',
    Error: 'red'
  };
  const apdexMin = {
    Healthy: 0.75,
    Warning: 0.5,
    Error: -1
  };
  const apdexMax = {
    Healthy: 1,
    Warning: 0.75,
    Error: 0.5
  };

  var apps = [];

  tableData.forEach(application => {
    if (typeof application.application_summary !== 'undefined') {
      const apdex = application.application_summary.apdex_score;
      if (apdex > apdexMin[tableFilter] && apdex <= apdexMax[tableFilter]) {
        apps.push(application);
      }
    } else {
      if (tableFilter === 'Error') {
        apps.push(application);
      }
    }
  });
  const tableRows = apps.map((prop, key) => {
    const statusColor = healthStatusToColor[tableFilter] || 'black';
    const path = `/admin/app-status/${prop.application_name}`;
    return (
      <TableRow key={prop.application_name} className={classes.tableRow}>
        <TableCell className={classes.tableCell}>
          <Link component={RouterLink} to={path}>
            {prop.application_name}
            <IconButton>
              <Visibility />
            </IconButton>
          </Link>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Icon className={classes.icon} style={{ color: statusColor }}>
            fiber_manual_record
          </Icon>
        </TableCell>
        <TableCell className={classes.tableCell} style={{ color: statusColor }}>
          {prop.health_status}
        </TableCell>
        <TableCell className={classes.tableCell} style={{ color: statusColor }}>
          {prop.application_summary.apdex_score || 0}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {prop.application_summary.error_rate}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {prop.application_summary.instance_count}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {prop.application_summary.response_time}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {prop.application_summary.throughput}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{tableRows}</TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(CustomTable);

import {
  FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FAILED,
  FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FINISHED,
  FETCH_PROJECT_STATUS_CATEGORY_COUNTS_STARTED
} from '../features/constants/chartConstants';

export default (
  state = {
    results: [],
    meta: { fetching: false, errorMessages: [] }
  },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECT_STATUS_CATEGORY_COUNTS_STARTED:
      return { ...state, meta: { fetching: true, errorMessages: [] } };
    case FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FINISHED:
      return {
        results: [...action.payload],
        meta: { fetching: false, errorMessages: [], lastUpdated: new Date() }
      };
    case FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FAILED:
      return {
        ...state,
        meta: { fetching: false, errorMessages: action.payload }
      };
    default:
      return state;
  }
};

import { READ_ALL_SERVICES_SUCCESS } from '../constants/serviceActionTypes';

const servicesReducer = (state = [], action) => {
  switch (action.type) {
    case READ_ALL_SERVICES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default servicesReducer;

export const FETCH_STATUSES_STARTED = 'FETCH_STATUSES_STARTED';
export const FETCH_STATUSES_FINISHED = 'FETCH_STATUSES_FINISHED';
export const FETCH_STATUSES_FAILED = 'FETCH_STATUSES_FAILED';
export const FETCH_PROJECT_STATUS_CATEGORY_COUNTS_STARTED =
  'FETCH_PROJECT_STATUS_CATEGORY_COUNTS_STARTED';
export const FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FINISHED =
  'FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FINISHED';
export const FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FAILED =
  'FETCH_PROJECT_STATUS_CATEGORY_COUNTS_FAILED';
export const FETCH_ANALYSIS_STATUSES_STARTED =
  'FETCH_ANALYSIS_STATUSES_STARTED';
export const FETCH_ANALYSIS_STATUSES_FINISHED =
  'FETCH_ANALYSIS_STATUSES_FINISHED';
export const FETCH_ANALYSIS_STATUSES_FAILED = 'FETCH_ANALYSIS_STATUSES_FAILED';
export const projects = {
  OM: 'OM- Single Evaluation Processing System',
  AER: 'New Development -AER',
  ASB: 'New Development -ASBS20'
};

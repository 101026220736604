import newRelic from '../../apis/newRelic';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const fetchSystemDetails = () => async dispatch => {
  dispatch({ type: 'SYSTEMDETAIL_STARTED' });
  const apps = { data: [] };
  try {
    const response = await newRelic.get(`/applications.json`);
    await asyncForEach(response.data.applications, async application => {
      const appId = application.id;
      const instanceResponce = await newRelic.get(
        '/applications/' + appId + '/instances.json'
      );
      if (
        instanceResponce.data.application_instances === undefined ||
        instanceResponce.data.application_instances.length === 0
      ) {
        const app = {
          application_name: application.name,
          health_status: 'Unavailible',
          application_summary: { apdex_score: 0 }
        };
        apps.data.push(app);
      } else {
        instanceResponce.data.application_instances.forEach(instance => {
          if (typeof instance.application_summary === 'undefined') {
            instance.application_summary = { apdex_score: 0 };
          }
          const { apdex_score } = instance.application_summary;
          instance.health_status =
            apdex_score > 0.75 ? 'Green' : apdex_score > 0.5 ? 'Yellow' : 'Red';
          apps.data.push(instance);
        });
      }
    });
    dispatch({ type: 'SYSTEMDETAIL', payload: apps });
  } catch (error) {
    dispatch({
      type: 'SYSTEMDETAILERROR',
      payload: error.response ? error.response.data.errorMessages : [error]
    });
  }
};

export default fetchSystemDetails;

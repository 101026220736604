import React from 'react';
import Card from '../../common/components/Card/Card.jsx';
import CardHeader from '../../common/components/Card/CardHeader.jsx';
import CardBody from '../../common/components/Card/CardBody.jsx';
import ConceptsList from './ConceptsList';

const Concepts = ({ classes }) => {
  return (
    <Card>
      <CardHeader color="success">
        <h4 className={classes.cardTitleWhite}>Relevant Innovative Concepts</h4>
      </CardHeader>
      <CardBody>
        <ConceptsList />
      </CardBody>
    </Card>
  );
};

export default Concepts;

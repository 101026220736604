import confluence, { BASE_URL } from '../apis/confluence';
import _ from 'lodash';
import {
  FETCH_ARTICLES_STARTED,
  FETCH_ARTICLES_FINISHED,
  FETCH_ARTICLES_FAILED,
  FETCH_FEATURED_ARTICLES_FAILED,
  FETCH_FEATURED_ARTICLES_FINISHED,
  FETCH_FEATURED_ARTICLES_STARTED
} from '../constants/articleSearchConstants';

const fetchByTerm = (
  startType,
  finishedType,
  errorType
) => term => async dispatch => {
  dispatch({ type: startType });
  try {
    const project = 'C';

    const articlesResponse = await confluence.get(
      `content/search?cql=type=page%20AND%20space=${project}%20AND%20label=${term}`
    );

    const articles = _.map(articlesResponse.data.results, result => {
      return {
        title: result.title,
        link: BASE_URL.concat(result._links.webui)
      };
    });

    dispatch({
      type: finishedType,
      payload: articles
    });
  } catch (error) {
    dispatch({
      type: errorType,
      payload: error.response ? error.response.data.message : error
    });
  }
};

export const fetchArticles = fetchByTerm(
  FETCH_ARTICLES_STARTED,
  FETCH_ARTICLES_FINISHED,
  FETCH_ARTICLES_FAILED
);
export const fetchFeaturedArticles = fetchByTerm(
  FETCH_FEATURED_ARTICLES_STARTED,
  FETCH_FEATURED_ARTICLES_FINISHED,
  FETCH_FEATURED_ARTICLES_FAILED
);

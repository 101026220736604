import {
  beginAjaxCall,
  ajaxCallError,
  endAjaxCall
} from '../../common/actionCreators/ajaxStatusActions';

import { READ_ALL_SERVICES_SUCCESS } from '../constants/serviceActionTypes';

import readAllServicesApi from '../apis/readAllServicesApi';

const readAllServicessSuccess = services => {
  return { type: READ_ALL_SERVICES_SUCCESS, payload: services };
};

const readAllServices = () => dispatch => {
  dispatch(beginAjaxCall('services'));
  return readAllServicesApi()
    .then(services => {
      dispatch(readAllServicessSuccess(services));
      dispatch(endAjaxCall('services'));
    })
    .catch(error => {
      dispatch(ajaxCallError('services', error));
    });
};

export default readAllServices;

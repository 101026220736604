import React from 'react';
import Card from '../common/components/Card/Card.jsx';
import CardHeader from '../common/components/Card/CardHeader.jsx';
import CardBody from '../common/components/Card/CardBody.jsx';
import { withStyles } from '@material-ui/core/styles';
import fetchValidationBreakdown from '../actions/fetchValidationBreakdown.js';
import { connect } from 'react-redux';
import RefreshCardFooter from '../common/components/Card/RefreshCardFooter.jsx';
import Icon from '@material-ui/core/Icon';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  cardHeader: {
    padding: '15px',
    marginTop: '-20px',
    borderRadius: '3px',
    background: '#284860',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4)',
    color: '#FFF',
    margin: '0 15px',
    position: 'relative',
    zIndex: '3 !important',
    borderBottom: 'none'
  },
  cardTitle: {
    marginTop: '0px',
    minHeight: 'auto',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardCategory: {
    color: '#3f4c31',
    margin: 0,
    marginTop: 0,
    marginBottom: 0
  },
  card: {
    maxWidth: 400
  }
});

const SystemValidation = ({
  classes,
  fetchValidationBreakdown,
  validationBreakdown,
  footerClass,
  prop
}) => {
  const path = '/admin/validation-details/';
  return (
    <Card>
      <CardHeader className={classes.cardHeader}>
        <h4 className={classes.cardTitle}>HRC System Validation Results</h4>
      </CardHeader>
      <CardBody>
        <div>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              width: '33%'
            }}
          >
            <Icon
              className={classes.icon}
              fontSize="large"
              style={{ color: '#4caf50' }}
            >
              fiber_manual_record
            </Icon>
            <Link
              component={RouterLink}
              to={path + 'Success'}
              style={{ fontSize: 20 }}
            >
              {validationBreakdown.success}/{validationBreakdown.total}
            </Link>
          </div>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              width: '33%'
            }}
          >
            <Icon
              className={classes.icon}
              fontSize="large"
              style={{ color: 'orange' }}
            >
              fiber_manual_record
            </Icon>
            <Link
              component={RouterLink}
              to={path + 'Warning'}
              style={{ fontSize: 20 }}
            >
              {validationBreakdown.warning}/{validationBreakdown.total}
            </Link>
          </div>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              width: '33%'
            }}
          >
            <Icon
              className={classes.icon}
              fontSize="large"
              style={{ color: 'red' }}
            >
              fiber_manual_record
            </Icon>
            <Link
              component={RouterLink}
              to={path + 'Failed'}
              style={{ fontSize: 20 }}
            >
              {validationBreakdown.failed}/{validationBreakdown.total}
            </Link>
          </div>
        </div>
      </CardBody>
      <RefreshCardFooter
        footerClass={footerClass}
        lastUpdated={validationBreakdown.timestamp}
      />
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    validationBreakdown: state.validationBreakdown
  };
};

export default connect(
  mapStateToProps,
  { fetchValidationBreakdown }
)(withStyles(styles)(SystemValidation));

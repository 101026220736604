const fetchValidationBreakdown = () => async dispatch => {
  var breakdown = {
    failed: 2,
    warning: 2,
    success: 178,
    total: 182,
    timestamp: new Date()
  };
  dispatch({ type: 'VALIDATIONBREAKDOWN', payload: breakdown });
};

export default fetchValidationBreakdown;

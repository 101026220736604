import camunda from '../apis/camunda';

const fetchWorkflowSummary = () => async dispatch => {
  var summary = {
    misfire: 0,
    defam: 0,
    ipermsError: 0,
    completedEvaluations: 0
  };

  const response = await camunda.get(`/process-definition`);
  var processID = '';
  response.data.forEach(process => {
    if (process.key === 'evaluation') {
      processID = process.id;
    }
  });
  var taskResponce;
  if (processID !== '') {
    taskResponce = await camunda.get(
      '/process-definition/' + processID + '/statistics'
    );
  }

  taskResponce.data.forEach(task => {
    if (task.id === 'Task_1m7x64v') {
      summary.misfire = task.instances;
    } else if (task.id === 'Task_01g6e98') {
      summary.defam = task.instances;
    } else if (task.id === 'Task_0ec7yhs') {
      summary.ipermsError = task.instances;
    } else {
      summary.completedEvaluations = task.instances;
    }
  });

  dispatch({ type: 'WORKFLOWSUMMARY', payload: summary });
};

export default fetchWorkflowSummary;

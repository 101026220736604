import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserShield,
  faProjectDiagram,
  faNetworkWired,
  faThLarge,
  faPeopleCarry,
  faClone,
  faToolbox,
  faLightbulb,
  faLifeRing,
  faComments,
  faIdCard,
  faEnvelope,
  faEye,
  faFolderOpen
} from '@fortawesome/free-solid-svg-icons';

import reducers from './reducers';

// core components
import Admin from 'layouts/Admin.jsx';
import RTL from 'layouts/RTL.jsx';

import 'assets/css/material-dashboard-react.css?v=1.6.0';

import DataFetcher from './DataFetcher';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

library.add(
  faUserShield,
  faProjectDiagram,
  faNetworkWired,
  faThLarge,
  faPeopleCarry,
  faClone,
  faToolbox,
  faLightbulb,
  faLifeRing,
  faComments,
  faIdCard,
  faEnvelope,
  faEye,
  faFolderOpen
);

const hist = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router history={hist}>
        <CssBaseline />
        <DataFetcher />
        <Switch>
          <Route
            path={[
              '/admin/app-status/:appName',
              '/admin/app-cost/:appName',
              '/admin/FinancialDetails',
              '/admin/CyberReadinessDetails',
              '/admin/WorkflowDetails',
              '/admin'
            ]}
            component={Admin}
          />
          <Route path="/rtl" component={RTL} />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

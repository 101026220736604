import newRelic from '../apis/newRelic';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const fetchStatusBreakdown = () => async dispatch => {
  dispatch({ type: 'STATUSBREAKDOWN_STARTED' });
  try {
    const response = await newRelic.get(`/applications.json`);
    var breakDown = {
      error: 0,
      warning: 0,
      ok: 0,
      total: 0,
      timestamp: new Date()
    };
    await asyncForEach(response.data.applications, async application => {
      const appId = application.id;
      const instanceResponce = await newRelic.get(
        '/applications/' + appId + '/instances.json'
      );

      if (
        instanceResponce.data.application_instances === undefined ||
        instanceResponce.data.application_instances.length === 0
      ) {
        breakDown.error = breakDown.error + 1;
        breakDown.total = breakDown.total + 1;
      } else {
        instanceResponce.data.application_instances.forEach(instance => {
          if (typeof instance.application_summary !== 'undefined') {
            const apdex = instance.application_summary.apdex_score;
            if (apdex <= 0.5) {
              breakDown.error = breakDown.error + 1;
            } else if (apdex <= 0.75) {
              breakDown.warning = breakDown.warning + 1;
            } else {
              breakDown.ok = breakDown.ok + 1;
            }
            breakDown.total = breakDown.total + 1;
          } else {
            breakDown.error = breakDown.error + 1;
            breakDown.total = breakDown.total + 1;
          }
        });
      }
    });
    dispatch({ type: 'STATUSBREAKDOWN', payload: breakDown });
  } catch (error) {
    dispatch({
      type: 'BREAKDOWNERROR',
      payload: error.response ? error.response.data.errorMessages : [error]
    });
  }
};

export default fetchStatusBreakdown;

import React, { useState } from 'react';
import Card from '../common/components/Card/Card.jsx';
import CardHeader from '../common/components/Card/CardHeader.jsx';
import CardBody from '../common/components/Card/CardBody.jsx';
import GridItem from '../common/components/Grid/GridItem.jsx';
import Table from './components/AppIssuesTable.jsx';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import fetchAppIssues from './functions/fetchAppIssues';
import { connect } from 'react-redux';
import RefreshCardFooter from '../common/components/Card/RefreshCardFooter.jsx';
import { TextField, MenuItem } from '@material-ui/core';

const styles = theme => ({
  cardHeader: {
    padding: '15px',
    marginTop: '-20px',
    borderRadius: '3px',
    background: '#943a3a',
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 0, 0,.4)',
    color: '#FFF',
    margin: '0 15px',
    position: 'relative',
    zIndex: '3 !important',
    borderBottom: 'none',
    display: 'flex'
  },
  titleSection: {
    float: 'left',
    flex: 1
  },
  buttonContainer: {
    display: 'flex',
    flex: 1
  },
  cardTitle: {
    marginTop: '0px',
    minHeight: 'auto',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardCategory: {
    color: 'white',
    float: 'right',
    backgroundColor: '#a54040',
    fontWeight: '405',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    borderStyle: 'none',
    fontSize: 'large',
    height: '35px',
    margin: '7px'
  },
  card: {
    maxWidth: 400
  },
  button: {
    backgroundColor: '#a54040',
    color: 'white',
    margin: '2%',
    marginTop: '6px',
    flex: 1
  },
  outlinedRoot: {
    padding: '0px'
  },
  notchedOutline: {
    padding: '0px'
  }
});

const AppIssues = ({ classes, fetchAppIssues, appIssues, footerClass }) => {
  const [isOpen, setIsOpen] = useState(true);

  var timestamp = new Date();
  if (appIssues != null) timestamp = appIssues[0].timestamp;

  return (
    <GridItem xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardHeader className={classes.cardHeader}>
          <div className={classes.titleSection}>
            <h4 className={classes.cardTitle}>
              Application Development Roadblocks
            </h4>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              onClick={() => {
                setIsOpen(true);
              }}
              variant={isOpen ? 'text' : 'contained'}
            >
              Open
            </Button>
            <Button
              className={classes.button}
              onClick={() => {
                setIsOpen(false);
              }}
              variant={isOpen ? 'contained' : 'text'}
            >
              Closed
            </Button>
            <Button
              className={classes.button}
              href="https://saicagile.atlassian.net/browse/ASB-87?jql=project%20in%20(OM%2C%20AER%2C%20ASB)%20AND%20issuetype%20in%20(Bug%2C%20Story%2C%20Task)%20order%20by%20created%20DESC"
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
            >
              Details
            </Button>
          </div>
          <TextField
            select
            variant="outlined"
            value={1}
            className={classes.outlinedRoot}
            InputProps={{
              style: {
                color: 'white',
                backgroundColor: '#a54040',
                borderRadius: '4px',
                borderStyle: 'none',
                height: '36px',
                marginTop: '6px',
                boxShadow:
                  '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
              }
            }}
          >
            <MenuItem value="0">
              <em>All</em>
            </MenuItem>
            <MenuItem value="1">BEPB Portfolio</MenuItem>
          </TextField>
          <a
            className="tooltip"
            style={{
              color: '#d96f6f',
              marginTop: '30px',
              position: 'absolute'
            }}
            href="https://saicagile.atlassian.net/secure/PortfolioPlanView.jspa?id=18&sid=18#backlog"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Portfolio
          </a>
        </CardHeader>
        <CardBody>
          <Table
            tableHead={[
              'Issue Number',
              'Summary',
              'Severity',
              'Status',
              'Created'
            ]}
            tableData={{ appIssues: appIssues || [], status: isOpen }}
          />
        </CardBody>
        <RefreshCardFooter footerClass={footerClass} lastUpdated={timestamp} />
      </Card>
    </GridItem>
  );
};

const mapStateToProps = state => {
  return {
    appIssues: state.appIssues.data
  };
};

export default connect(
  mapStateToProps,
  { fetchAppIssues }
)(withStyles(styles)(AppIssues));
